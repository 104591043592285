import React, {useState, useEffect, useRef,useContext} from "react";
// import PropTypes from "prop-types";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import VideoBarNft from "./VideoBarNft.jsx";
import crossIcon from "../../../assets/logos/crossIcon.svg";

import { VideoContext } from './VideoProvider';
import axios from "axios";

const VideoJS = React.forwardRef((props, ref) => {
    const videoRef = useRef(null);
    const playerRef = useRef(null);
    const {options, onReady} = props;
    const [indexVideo, setIndexVideo] = useState(props.indexVideo);
    const [buyTab, setBuyTab] = useState();
    const [bought, setBought] = useState();
    const [axiosSent, setAxiosSent] = useState(false);
    const [secondsElapsed, setSecondsElapsed] = useState(0);

    const [currentAchat, setCurrentAchat] = useState(0);
    const [id, setId] = useState(props.id);
    const [shop,setShop] = useState(false);
    const [isPlay,setIsPlay] = useState(false);

    const [message, setMessage] = useState("");

    const token = props.token;
    const intervalIdRef = useRef();
    const handleFullScreen = () => {
        const player = playerRef.current;
        let time = player.cache_.currentTime;
        player.pause();
        let videofull = document.querySelector(".homepageGlobal");
        if (videofull) {
            // Passer en plein écran
            if (videofull.requestFullscreen) {
                videofull.requestFullscreen();
            } else if (videofull.mozRequestFullScreen) { // Firefox
                videofull.mozRequestFullScreen();
            } else if (videofull.webkitRequestFullscreen) { // Chrome, Safari et Opera
                videofull.webkitRequestFullscreen();
            } else if (videofull.msRequestFullscreen) { // IE/Edge
                videofull.msRequestFullscreen();
            }
            // Changement de l'orientation de l'écran en paysage
            const isMobileOrTablet = () =>
                typeof window.orientation !== "undefined"
                || navigator.userAgent.indexOf('IEMobile') !== -1
                || 'ontouchstart' in window;
            if (isMobileOrTablet()) {
                if (screen.orientation && screen.orientation.lock) {
                    screen.orientation.lock('landscape').catch((error) => {
                        console.error('Could not lock screen orientation', error);
                    });
                }
            }
            props.handleCallBackFullScreen(true, indexVideo, time)
        }
    }
    const handleJumpToTime = async (time, classbtn, index) => {
        let select = `#video_${indexVideo} .tabBarBuy button`;
        let btn = document.querySelectorAll(select);
        await allbtn.map((value, index) => {
            value.childNodes[0].childNodes[0].classList.add('close')
            value.childNodes[0].childNodes[0].classList.remove('open')
        })
        const player = playerRef.current;
        if (classbtn == "avendre") {
            btn[index].childNodes[0].classList.remove('close')
            btn[index].childNodes[0].classList.add('open')
            setCurrentAchat(time);
            player.currentTime(time);
        } else {
            player.currentTime(time);
        }
    }
    const handleCreateBarNft = () => {
        let tab = [];
        for (let i = 0; i < props.duration; i += 5) {
            let j = i / 5;
            let classBtn = "indispo";
            buyTab.map((vbuy) => {
                if (vbuy.startTime === i) {
                    classBtn = "vendu";
                }
            })
            bought.map((vboug) => {
                if (vboug.startTime === i) {
                    classBtn = "avendre";
                }
            })
            tab.push(
                <button
                    className={classBtn}
                    key={i}
                    onClick={() => handleJumpToTime(i, classBtn, j)}
                >
                    <button className="bullMenu close" onClick={() => handleAcheter(j)}>Acheter</button>
                </button>
            );
        }
        setTabBar(tab);
    };
    useEffect(() => {
        setBought(props.videosBought);
    }, [props.videosBought]);
    useEffect(() => {
        if(localStorage.getItem('isNft')){
            setShop(true);
        }
        if (!playerRef.current) {
            const videoElement = document.createElement("video-js");
            videoElement.classList.add("vjs-big-play-centered");
            videoRef.current.appendChild(videoElement);

            let isTimeUpdated = false;
            const player = (playerRef.current = videojs(videoElement, options, () => {
                onReady && onReady(player);

            }));
            player.on('canplay', function () {
                if (!isTimeUpdated) {
                    this.currentTime(options.currentTime);
                    isTimeUpdated = true;
                }

            });

            player.controlBar.addChild(
                "button",
                {
                    text: "FullScreen",
                    el: videojs.dom.createEl("FullButton", {
                        className: "vjs-fullscreen-control",
                        title: "FullScreen",
                        role: "button",
                    }),
                    clickHandler: handleFullScreen,
                },
                player.controlBar.children().length - 0
            );

            player.controlBar.addChild(
                "button",
                {
                    text: "Restart",
                    el: videojs.dom.createEl("RestartButton", {
                        className: "vjs-icon-restart",
                        title: "Restart",
                        role: "button",
                    }),
                    clickHandler: function () {
                        player.currentTime(0);
                        player.play().catch(function (erreur) {

                        });
                        ;
                    },
                },
                player.controlBar.children().length - 0
            );
        } else {
            const player = playerRef.current;
            player.autoplay(options.autoplay);
            player.src(options.sources);
            setBuyTab(player.options_.sources.videosToBuy);
            setBought(player.options_.sources.videosBought);
        }
    }, [options, videoRef, currentAchat]);
    useEffect(() => {
        const player = playerRef.current;
        return () => {
            if (player && !player.isDisposed()) {
                player.dispose();
                playerRef.current = null;
            }
        };
    }, [playerRef]);

    useEffect(() => {

        const player = playerRef.current;

        player.on('play', function() {
            intervalIdRef.current = setInterval(() => {
                if (!axiosSent) {
                    setSecondsElapsed(secondsElapsed => secondsElapsed + 1);
                } else {
                    clearInterval(intervalIdRef.current);
                }
            }, 1000);
        });
        player.on('pause', function() {
            clearInterval(intervalIdRef.current);
        });

        return () => {
            clearInterval(intervalIdRef.current);
        };
    }, []);
    useEffect(() => {
        if(secondsElapsed > 30 && !axiosSent){
            let url = import.meta.env.VITE_BACKEND_URL + "/videos/viewUpdate";
            axios.post(url, {
                token
            }).then((response) => {
                setAxiosSent(true);
                clearInterval(intervalIdRef.current);
            }).catch((error) => {
                console.log(error);
            });
        }
    }, [secondsElapsed,axiosSent]);
    useEffect(() => {
        const player = playerRef.current;

        const observer = new IntersectionObserver(
            entries => {
                // entries[0] est le premier élément observé
                if (entries[0].isIntersecting) {
                    // Si l'élément est visible, lancez la vidéo
                    player.play().catch(function (erreur) {

                    });
                    setIsPlay(true);
                    //props.callbackIndexVideo(props.indexID);
                } else {
                    // Si l'élément n'est pas visible, mettez la vidéo en pause
                    if (player) { // Ajout de cette vérification
                        player.pause();
                    }
                    setIsPlay(false);
                }
            },
            {
                // Définir le pourcentage de visibilité pour déclencher l'observer
                threshold: 0.5
            }
        );

        // Commencer à observer l'élément vidéo
        observer.observe(videoRef.current);

        // Nettoyer après utilisation
        return () => {
            if (videoRef.current) {
                observer.unobserve(videoRef.current);
            }
        };
    }, []);

    useEffect(() => {
        if (playerRef.current) {
            const player = playerRef.current;

            player.on('play', function() {
                setIsPlay(true);
            });

            player.on('pause', function() {
                setIsPlay(false);
            });
        }

        // Nettoyez les écouteurs d'événements lorsque le composant est désinstallé
        return () => {
            if (playerRef.current) {
                playerRef.current.off('play');
                playerRef.current.off('pause');
            }
        };
    }, [playerRef]);
    useEffect(() => {
        return () => {
            localStorage.removeItem('isNft');
        };
    }, []);

    const handleBarNftShow = () => {
        setShop(!shop)
    }

    return (
        <div data-vjs-player className="video-js-player">
            <div ref={videoRef} className="video-js"/>
            {
                message !== "" &&
                <div className="nftmessage">

                    {message}
                </div>
            }
            <div className="barBuy">
                <div className={`tabBarBuy ${shop ? "open" : "close"}`}>
                    {
                        shop ?

                            <>
                                <button className="btn_close" onClick={handleBarNftShow}><img src={crossIcon} alt=""/>
                                </button>
                                <VideoBarNft video={props.video} user={props.user} videosToBuy={props.videosToBuy} videosBought={props.videosBought}
                                             time={props.duration} isPlaying={isPlay} videoRef={videoRef} lang={props.lang} />
                            </>
                            :
                            <button className="ntf_btn_show" onClick={handleBarNftShow}>Acheter cet
                                instant</button>
                    }
                </div>
            </div>
        </div>
    );
});

// VideoJS.propTypes = {
//   options: PropTypes.object.isRequired,
//   onReady: PropTypes.func.isRequired,
// };

export default VideoJS;
