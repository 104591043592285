import React from 'react';
import eye from "../../../../assets/logos/eye.svg";
import chevron from "../../../../assets/logos/chevron.svg";
import vignette2 from "../../../../assets/backgrounds/1_big.png";
import en from '../../../../locales/en/translation.json';
import fr from '../../../../locales/fr/translation.json';

export default class LastLike extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            videos:this.props.videos,
            lang:'en',
            trad:en,
        }
    }

    componentDidMount() {
        if (JSON.parse(localStorage.getItem("user"))) {
            this.setState({
                user: JSON.parse(localStorage.getItem("user")),
            }, () => {
                let lang = this.state.user.user.language;
                if(lang === "fr"){
                    this.setState({lang:"fr",trad:fr});
                }
            });
        }
    }


    render(){
        return(
            <div className="lastlike">
                <h2>{this.state.trad.pDescriptionUtilisateur1} <img style={{ marginLeft:"8px"}} src={chevron} alt=""/></h2>
                <div className="line">
                    {
                        this.state.videos != null &&
                        this.state.videos.map((value, index) => (
                            <div className="block_video" key={index}>
                                <div className="img" onClick={() => this.handleEditVideo(index)}>
                                    <a href={`/video/${value.video.token}`}>
                                        <img src={value.video.miniature !== null ? import.meta.env.VITE_BACKEND_URL_IMG + value.video.miniature : vignette2}
                                             alt=""/>
                                        <p className="time">{value.video.time}</p>

                                    </a>
                                </div>
                                <div className="info_video">
                                    <p className="title">{value.video.title}</p>
                                    {
                                        value.user &&
                                        value.user.nom_artiste &&
                                        <p className="creator">{value.user.nom_artiste}</p>
                                    }
                                    <p className="view"><img src={eye} alt=""/> {value.video.vue}</p>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        )
    }

}
