import {useState, useEffect} from "react";
import {BrowserRouter as Router} from "react-router-dom";
import Header from "./resources/js/components/Header";
import Footer from "./resources/js/components/Footer";
import AllRoutes from "./resources/AllRoutes";
import VideoDataContext, {VideoDataProvider} from './resources/VideoDataContext';
import ShopBuy from "./resources/js/components/ShopBuy";

function App() {
    const [openShop, setOpenShop] = useState(false);

    useEffect(() => {
        let path = document.location.pathname;
        path = path.split('/');
        if (path[1] !== "gestion-de-ma-page" && path[1] !== "mes-places") {
            document.querySelector('.img_cover').style.backgroundImage = "none";
        }
    });

    const handleOpenShop = () => {
        setOpenShop(!openShop);
    };

    return (
        <VideoDataProvider>
            <div className="App">
                <Router>
                    <Header openShop={handleOpenShop}/>
                    <ShopBuy openShop={openShop}/>
                    <div className="content">
                        <div className="img_cover"></div>
                        <AllRoutes/>
                    </div>
                    <Footer/>
                </Router>
            </div>
        </VideoDataProvider>
    );
}

export default App;
