import React from "react";
import { Link } from "react-router-dom";
import wonderplaceBetaFooter from "../../../assets/logos/wonderplaceBetaFooter.svg";
import instagram from "../../../assets/logos/instagram.svg";
import tiktok from "../../../assets/logos/tiktok.svg";
import twitter from "../../../assets/logos/twitter.svg";
import connectWallet from "../../../assets/logos/connectWallet.svg";
import connectWalleten from "../../../assets/logos/en/connectWalleten.svg";
import uploadVideo from "../../../assets/logos/uploadVideo.svg";
import uploaden from "../../../assets/logos/en/uploaden.svg";
import wonderplaceLogo from "../../../assets/logos/logowonder.svg";


import en from '../../../locales/en/translation.json';
import fr from '../../../locales/fr/translation.json';

export default class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user:null,
      lang:'en',
      trad:en,
    };
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("user"))) {
      this.setState({
        user: JSON.parse(localStorage.getItem("user")),
      }, () => {
        let lang = this.state.user.user.language;
        if(lang === "fr"){
          this.setState({lang:"fr",trad:fr});
        }
      });
    }
  }


  render() {
    return (
        <div className="footerGlobal">
          <div className="background">
            <Link to="/" className="wonderplaceBetaFooterLogo">
              <img src={wonderplaceBetaFooter} alt="wonderplaceBetaFooterLogo" />
            </Link>

            <div className="footerSlogan">
              <p>We believe in music</p>
              <p>{this.state.trad.footerSlogan}</p>
            </div>

            <div className="socialLogos">
              <img src={instagram} className="instagramLogo" alt="instagramLogo" />
              <img src={tiktok} className="tiktokLogo" alt="tiktokLogo" />
              <img src={twitter} className="twitterLogo" alt="twitterLogo" />
            </div>

            <div className="footerButtons">
              <img
                  src={this.state.lang === "fr" ? connectWallet : connectWalleten}
                  className="connectWalletLogo"
                  alt="connectWalletLogo"
              />
              {
                this.state.user !== null &&
                this.state.user.creator == 1 ?
                    <Link className="linkUploadVideo" to="/gestion-de-ma-page/upload-video">
                      <img
                          src={this.state.lang === "fr" ? uploadVideo : uploaden}
                          //src={uploadVideo}
                          className="uploadVideoLogo"
                          alt="uploadVideoLogo"
                      />
                    </Link>
                    :
                    <Link  className="linkUploadVideo" to="/mon-wallet">
                      <img
                          src={this.state.lang === "fr" ? connectWallet : connectWalleten}
                          className="uploadVideoLogo"
                          alt="connectWalletLogo"
                      />
                    </Link>
              }
            </div>

            <div className="columnsGlobal">
              <ul className="footerFirstColumn">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/aidesfaq">{this.state.trad.headerNavSub6}</Link>
                </li>
                <li>
                  <Link to="/aidesfaq">{this.state.trad.footerFourthColumn5}</Link>
                </li>
                <Link to="/aidesfaq">{this.state.trad.footerFourthColumn6}</Link>
              </ul>
              <div className="columns2-3-4">
                <ul className="footerSecondColumn">
                  <li>
                    <Link to="/aidesfaq">{this.state.trad.footerFourthColumn7}</Link>
                  </li>
                  <li>
                    <Link to="/aidesfaq">{this.state.trad.footerFourthColumn8}</Link>
                  </li>
                  <li>
                    <Link to="/aidesfaq">{this.state.trad.footerFourthColumn9}</Link>
                  </li>
                  <li>
                    <Link to="/aidesfaq">{this.state.trad.footerFourthColumn10}</Link>
                  </li>
                  <li>
                    <Link to="/aidesfaq">{this.state.trad.footerFourthColumn11}</Link>
                  </li>
                  <li>
                    <Link to="/aidesfaq">{this.state.trad.footerFourthColumn12}</Link>
                  </li>
                </ul>

                <ul className="footerThirdColumn">
                  <li>
                    <Link to="/aidesfaq">{this.state.trad.footerFourthColumn13}</Link>
                  </li>
                  <li>
                    <Link to="/aidesfaq">{this.state.trad.footerFourthColumn14}</Link>
                  </li>
                  <li>
                    <Link to="/aidesfaq">{this.state.trad.footerFourthColumn15}</Link>
                  </li>
                  <li>
                    <Link to="/aidesfaq">{this.state.trad.footerFourthColumn16}</Link>
                  </li>
                </ul>

                <ul className="footerFourthColumn">
                  <li>
                    <Link target="_blank" to="https://www.wonderplace.tv/%C3%A0-propos">{this.state.trad.footerFourthColumn1}</Link>
                  </li>
                  <li>
                    <Link target="_blank" to="https://www.wonderplace.tv/blog">{this.state.trad.footerFourthColumn2}</Link>
                  </li>
                  <li>
                    <Link target="_blank" to="https://www.wonderplace.tv/contact">{this.state.trad.footerFourthColumn3}</Link>
                  </li>
                  <li>
                    <Link target="_blank" to="https://www.wonderplace.tv/devenir-partenaire">{this.state.trad.footerFourthColumn4}</Link>
                  </li>
                  {/*
                <li>
                  <Link to="/aidesfaq">Emplois</Link>
                </li>
              */}
                </ul>
              </div>
            </div>
          </div>
          <div className="creditsText">
            <p>© 2024 Wonderplace</p><span>|</span>
            <p>Silence éphémère</p><span>|</span>
            <Link to="https://retrokube.com" target="_blank">{this.state.trad.footerFourthColumn17}</Link>
          </div>
        </div>
    );
  }
}
