import React from "react";
import VideoPlayer from "../modules/VideoPlayer";
import "video.js/dist/video-js.css";
import VideoPlayerFull from "../modules/VideoPlayerFull.jsx";

import axios from "axios";
import SmallModal from "../modules/SmallModal.jsx";

import en from '../../../locales/en/translation.json';
import fr from '../../../locales/fr/translation.json';

import videoBig from "../../../assets/backgrounds/1_big.png";

export default class Homepage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            stockVideo: null,
            videos: [],
            videosToBuy: [],
            videosBought: [],
            playVideoId: 0,
            user: null,
            page: 1,
            full: false,
            indexVideo: 0,
            currentTime: 0,
            openModalBug: false,
            openModalContenu: false,
            openModal: false,
            openModalCom: false,
            openModalBook: false,
            openModalFollow: false,
            currentIdVideo: null,
            flashMessage: "",
            send: null,
            lang: 'en',
            trad: en,
            nbVideo: 0,
            load: false,
            nbIndexVideo: 2,
        };
    }

    componentDidMount() {
        this.loadData();
        if (JSON.parse(localStorage.getItem("user"))) {
            this.setState({
                user: JSON.parse(localStorage.getItem("user")),
            }, () => {
                let lang = this.state.user.user.language;
                if (lang === "fr") {
                    this.setState({lang: "fr", trad: fr});
                }
            });
        }
    }
    loadData = async () => {
        document.querySelector("body").classList.add("home");
        document.querySelector(".img_cover").style.display = "none";
        if (JSON.parse(localStorage.getItem("user"))) {
            await this.setState({
                user: JSON.parse(localStorage.getItem("user")),
            }, () => {
                let url = import.meta.env.VITE_BACKEND_URL + "/videos/list?page=" + this.state.page;
                //let url = import.meta.env.VITE_BACKEND_URL + "/videos/list";
                let artistInfos = [];
                axios.post(url, {
                    id: this.state.user.user.id,
                }).then((res) => {
                    res.data.data.map((value, index) => {
                        let video = {};

                        video.id = value.id;
                        video.isPlay = false;
                        video.src = import.meta.env.VITE_BACKEND_URL_IMG + value.path_nocompres;
                        video.type = "video/mp4";

                        video.poster = value.miniature != null ? import.meta.env.VITE_BACKEND_URL_IMG + value.miniature : videoBig;

                        video.artistPhoto = import.meta.env.VITE_BACKEND_URL_IMG + value.utilisateur.avatar;
                        video.artistName = `${value.utilisateur.lastname !== null ? value.utilisateur.lastname : ""} ${value.utilisateur.firstname !== null ? value.utilisateur.firstname : ""}`;
                        video.artistTitle = value.utilisateur.nom_artiste;
                        video.artistDescription = value.utilisateur.biographie;
                        video.artistMoreDetails = "Plus d’informations…";
                        video.worldArtiste = value.info && value.info.upload_artistWord ? value.info.upload_artistWord.replace(/\n/g, '<br />') : "";
                        video.otherInfo = value.info && value.info.upload_otherInfos ? value.info.upload_otherInfos.replace(/\n/g, '<br />') : "";
                        video.droit = value.droits ? value.droits : [];
                        video.hasLiked = value.hasLiked;
                        video.hasDisLiked = value.hasDisLiked;
                        video.isLiked = value.isLiked;
                        video.title = value.title;
                        video.isCommented = value.isCom;
                        video.isBoorkmarked = value.book;
                        video.isBooked = value.booked;
                        video.isFollowed = value.share;
                        video.genreIcon = [];
                        video.token = value.token;
                        video.userId = value.utilisateur_id;
                        video.nft = value.nft;
                        video.videosToBuy = value.videosToBuy;
                        video.videosBought = value.videosBought;
                        video.isOpenBook = value.info ? value.info.allow_Share : 0;
                        video.isOpenRepost = value.info ? value.info.allow_Repost : 0;
                        video.isOpenLike = value.info ? value.info.allow_ViewLikes : 0;
                        video.isOpenCom = value.info ? value.info.allow_Coms : 0;
                        artistInfos.push(video);
                    });
                    this.setState({
                        videos: artistInfos,
                    });
                }).catch(err => {
                    console.log(err)
                });
            });
        } else {
            let url = import.meta.env.VITE_BACKEND_URL + "/videos/list?page=" + this.state.page;
            //let url = import.meta.env.VITE_BACKEND_URL + "/videos/list";
            let artistInfos = [];

            await axios.get(url).then((res) => {
                res.data.data.map((value, index) => {
                    let video = {};
                    video.id = value.id;
                    video.isPlay = index == 0 ? true : false;
                    video.src = import.meta.env.VITE_BACKEND_URL_IMG + value.path_nocompres;
                    video.type = "video/mp4";
                    video.poster = value.miniature != null ? import.meta.env.VITE_BACKEND_URL_IMG + value.miniature : videoBig;
                    video.artistPhoto = import.meta.env.VITE_BACKEND_URL_IMG + value.utilisateur.avatar;
                    video.artistName = `${value.utilisateur.lastname !== null ? value.utilisateur.lastname : ""} ${value.utilisateur.firstname !== null ? value.utilisateur.firstname : ""}`;
                    video.artistTitle = value.utilisateur.nom_artiste;
                    video.artistDescription = value.utilisateur.biographie;
                    video.artistMoreDetails = "Plus d’informations…";
                    video.worldArtiste = value.info && value.info.upload_artistWord ? value.info.upload_artistWord.replace(/\n/g, '<br />') : "";
                    video.otherInfo = value.info && value.info.upload_otherInfos ? value.info.upload_otherInfos.replace(/\n/g, '<br />') : "";
                    video.droit = value.droits ? value.droits : [];
                    video.hasLiked = value.hasLiked;
                    video.hasDisLiked = value.hasDisLiked;
                    video.isLiked = value.isLiked;
                    video.title = value.title;
                    video.isCommented = value.isCom;
                    video.isBoorkmarked = value.book;
                    video.isBooked = value.booked;
                    video.isFollowed = value.share;
                    video.genreIcon = [];
                    video.token = value.token;
                    video.userId = value.utilisateur_id;
                    video.nft = value.nft;
                    video.videosToBuy = value.videosToBuy;
                    video.videosBought = value.videosBought;
                    video.isOpenBook = value.info ? value.info.allow_Share : 0;
                    video.isOpenRepost = value.info ? value.info.allow_Repost : 0;
                    video.isOpenLike = value.info ? value.info.allow_ViewLikes : 0;
                    video.isOpenCom = value.info ? value.info.allow_Coms : 0;
                    artistInfos.push(video);
                });
                this.setState({
                    videos: artistInfos,
                });
            }).catch(err => {
                console.log(err);
            });

        }
    }
    handleCallBackPlay = (idVideo) => {
        let {videos} = this.state;
        let updatedVideos = videos.map((value, index) => ({
            ...value,
            isPlay: index === idVideo,
        }));
        this.setState({videos: updatedVideos});
    };
    handleCallBackFullScreen = (value, indexVideo, time, type = null) => {
        console.log(value);
        if (value) {
            this.setState({full: value, indexVideo: indexVideo, currentTime: parseInt(time)});
        } else {
            if (type === "like") {
                this.setState({openModal: true}, () => {
                    this.setState({full: value, indexVideo: indexVideo, currentTime: parseInt(time)}, () => {
                        setTimeout(() => {
                            let video = document.getElementById('video_' + indexVideo);
                            const heightVideo = video.getBoundingClientRect().top - 50;
                            console.log(heightVideo)
                            window.scroll(0, heightVideo);
                        }, 100)
                    });
                });
            } else if (type === "comm") {
                this.setState({openModalCom: true}, () => {
                    this.setState({
                        full: value,
                        indexVideo: indexVideo,
                        currentIdVideo: indexVideo,
                        currentTime: parseInt(time)
                    }, () => {
                        setTimeout(() => {
                            let video = document.getElementById('video_' + indexVideo);
                            const heightVideo = video.getBoundingClientRect().top - 50;
                            window.scroll(0, heightVideo);
                        }, 100)
                    });
                })
            } else if (type === "book") {
                this.setState({openModalBook: true}, () => {
                    this.setState({
                        full: value,
                        indexVideo: indexVideo,
                        currentIdVideo: indexVideo,
                        currentTime: parseInt(time)
                    }, () => {
                        setTimeout(() => {
                            let video = document.getElementById('video_' + indexVideo);
                            const heightVideo = video.getBoundingClientRect().top - 50;
                            window.scroll(0, heightVideo);
                        }, 100)
                    });
                })
            } else if (type === "follow") {
                this.setState({openModalFollow: true}, () => {
                    this.setState({
                        full: value,
                        indexVideo: indexVideo,
                        currentIdVideo: indexVideo,
                        currentTime: parseInt(time)
                    }, () => {
                        setTimeout(() => {
                            let video = document.getElementById('video_' + indexVideo);
                            const heightVideo = video.getBoundingClientRect().top - 50;
                            window.scroll(0, heightVideo);
                        }, 100)
                    });
                })
            }else{
                this.setState({full: value, indexVideo: indexVideo, currentTime: parseInt(time)});
            }
        }
    }
    handleFullScreenChangeVideo = (index) => {
        this.setState({indexVideo: index});
    }
    callbackIndexVideo = (indexVideo) => {
        let {videos, page} = this.state;
        let nb = videos.length - 2;
        if (indexVideo == nb) {
            this.setState({load:true,indexVideo},() => {
                videos[this.state.indexVideo].isPlay = false;
                page++;
                let url = import.meta.env.VITE_BACKEND_URL + "/videos/list?page=" + page;
                axios.get(url).then((res) => {
                    if (res.data.data[0]) {
                        res.data.data.map((value, index) => {
                            let video = {};
                            video.id = value.id;
                            video.isPlay = false;
                            video.src = import.meta.env.VITE_BACKEND_URL_IMG + value.path_nocompres;
                            video.type = "video/mp4";
                            video.poster = value.miniature != null ? import.meta.env.VITE_BACKEND_URL_IMG + value.miniature : videoBig;
                            video.artistPhoto = import.meta.env.VITE_BACKEND_URL_IMG + value.utilisateur.avatar;
                            video.artistName = `${value.utilisateur.lastname !== null ? value.utilisateur.lastname : ""} ${value.utilisateur.firstname !== null ? value.utilisateur.firstname : ""}`;
                            video.artistTitle = value.utilisateur.nom_artiste;
                            video.artistDescription = value.utilisateur.biographie;
                            video.artistMoreDetails = "Plus d’informations…";
                            video.worldArtiste = value.info && value.info.upload_artistWord ? value.info.upload_artistWord.replace(/\n/g, '<br />') : "";
                            video.otherInfo = value.info && value.info.upload_otherInfos ? value.info.upload_otherInfos.replace(/\n/g, '<br />') : "";
                            video.droit = value.droits ? value.droits : [];
                            video.hasLiked = value.hasLiked;
                            video.hasDisLiked = value.hasDisLiked;
                            video.isLiked = value.isLiked;
                            video.title = value.title;
                            video.isCommented = value.isCom;
                            video.isBoorkmarked = value.book;
                            video.isBooked = value.booked;
                            video.isFollowed = value.share;
                            video.genreIcon = [];
                            video.token = value.token;
                            video.userId = value.utilisateur_id;
                            video.nft = value.nft;
                            video.videosToBuy = value.videosToBuy;
                            video.videosBought = value.videosBought;
                            video.isOpenBook = value.info ? value.info.allow_Share : 0;
                            video.isOpenRepost = value.info ? value.info.allow_Repost : 0;
                            video.isOpenLike = value.info ? value.info.allow_ViewLikes : 0;
                            video.isOpenCom = value.info ? value.info.allow_Coms : 0;
                            videos.push(video);
                        });
                        this.setState(prevState => {
                            const newVideos = prevState.videos.map((video, index) => {
                                return {...video, isPlay: index === indexVideo};
                            });

                            return {
                                page,
                                videos: newVideos,
                                indexVideo: indexVideo,
                                nbIndexVideo: prevState.nbIndexVideo + 3,
                            };
                        });
                    }
                }).catch(err => {
                    console.log(err);
                });
            })
        }
    }
    callBackFreshPage = () => {
        window.location.reload(true);
    }
    handleSendBug = (type, id) => {
        let send = [];
        if (type === "bug") {
            send.push(type);
            send.push("video");
            send.push(id);
            this.setState({send, openModalContenu: false, openModalBug: true});
        } else if (type === "contenu") {
            send.push(type);
            send.push("video");
            send.push(id);
            this.setState({send, openModalContenu: true, openModalBug: false});
        }
        if (type === "bug") {
            this.setState({send, currentIdVideo: id, openModalBug: true});
        } else {
            this.setState({send, currentIdVideo: id, openModalContenu: true});
        }
    }
    callbackSend = (message) => {
        const {send, user} = this.state;
        let url = import.meta.env.VITE_BACKEND_URL + "/simple/send";
        axios.post(url, {
            send,
            message: message,
            user
        }).then(res => {
            if (res.data) {
                this.handleCloseModal();
                this.setState({
                    typeFlash: 'success',
                    flashMessage: this.state.trad.messageCompte1,
                    send: null
                }, () => {
                    setTimeout(() => {
                        this.setState({flashMessage: ""})
                    }, 3000)
                });
            }
        }).catch(err => {
            this.setState({
                openModalContenu: false,
                openModalBug: false,
                typeFlash: 'warning',
                flashMessage: this.state.trad.messageCompte2
            }, () => {
                setTimeout(() => {
                    this.setState({flashMessage: ""})
                }, 3000)
            });
        })
    }
    handleCloseModal = () => {
        this.setState({send: null, currentIdVideo: null, openModalBug: false, openModalContenu: false});
    }

    render() {
        return (
            <>
                {
                    this.state.flashMessage !== "" &&
                    <div className={`message ${this.state.typeFlash}`}>
                        <p>{this.state.flashMessage}</p>
                    </div>
                }
                <div className="content">
                    <div className="img_cover"></div>
                    <div className={`home homepageGlobal ${this.state.videos[0] ? '' : 'empty'}`}>
                        {this.state.full ?
                            <div className="fullscreen">
                                <VideoPlayerFull
                                    indexVideo={this.state.indexVideo}
                                    id={this.state.indexVideo}
                                    key={this.state.indexVideo}
                                    video={this.state.videos[this.state.indexVideo]}
                                    infos={false}
                                    user={this.state.user}
                                    handleCallBackPlay={this.handleCallBackPlay}
                                    handleCallBackFullScreen={this.handleCallBackFullScreen}
                                    handleFullScreenChangeVideo={this.handleFullScreenChangeVideo}
                                    currentTime={this.state.currentTime}
                                />
                            </div>
                            :
                            this.state.videos[0] ?
                                this.state.videos.map((value, index) => (

                                    <VideoPlayer
                                        indexVideo={index}
                                        id={`video_${index}`}
                                        key={value.id}
                                        video={value}
                                        videosToBuy={value}
                                        videosBought={value}
                                        infos={false}
                                        handleCallBackPlay={this.handleCallBackPlay}
                                        handleCallBackFullScreen={this.handleCallBackFullScreen}
                                        currentTime={this.state.currentTime}
                                        callbackIndexVideo={this.callbackIndexVideo}
                                        callBackFreshPage={this.callBackFreshPage}
                                        handleSendBug={this.handleSendBug}
                                        user={this.state.user}
                                        full={this.state.full}
                                        trad={this.state.trad}
                                        openModal={this.state.openModal}
                                        nbVideo={this.state.nbVideo}
                                        openModalCom={
                                            this.state.currentIdVideo === null ?
                                                this.state.openModalCom
                                                :
                                                this.state.currentIdVideo === index ?
                                                    this.state.openModalCom : false
                                        }
                                        openModalBook={this.state.currentIdVideo === null ? this.state.openModalBook :
                                            this.state.currentIdVideo === index ?
                                                this.state.openModalBook : false}
                                        openModalFollow={this.state.currentIdVideo === null ? this.state.openModalFollow :
                                            this.state.currentIdVideo === index ?
                                                this.state.openModalFollow : false}
                                    />
                                ))
                                :
                                <div className="loading-wave">
                                    <div className="loading-bar"></div>
                                    <div className="loading-bar"></div>
                                    <div className="loading-bar"></div>
                                    <div className="loading-bar"></div>
                                </div>
                        }
                        {
                            this.state.load &&
                            <div className="loading-wave">
                                <div className="loading-bar"></div>
                                <div className="loading-bar"></div>
                                <div className="loading-bar"></div>
                                <div className="loading-bar"></div>
                            </div>
                        }
                    </div>
                </div>
                {
                    this.state.openModalBug &&
                    <SmallModal title={this.state.trad.pDescriptionUtilisateur3}
                                label={this.state.trad.pDescriptionUtilisateur9}
                                handleCloseModal={this.handleCloseModal}
                                callbackSend={this.callbackSend}/>
                }
                {
                    this.state.openModalContenu &&
                    <SmallModal title={this.state.trad.pDescriptionUtilisateur10}
                                label={this.state.trad.pDescriptionUtilisateur11}
                                handleCloseModal={this.handleCloseModal} callbackSend={this.callbackSend}/>
                }

            </>
        );
    }
}
