import React, {Component} from "react";
import {Link} from "react-router-dom";
import axios from "axios";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import MuiSwitch from "../../modules/MuiSwitch";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";

import NavigateBackButton from "../../modules/NavigateBackButton.jsx";
import continuerButton from "../../../../assets/logos/continuerButton.svg";
import changeValidButtonen from "../../../../assets/logos/en/continuerButtonen.svg";
import arrow from "../../../../assets/logos/arrow.svg";
import VideoUpload from "../../modules/VideoUpload.jsx";
import {bannerHeight} from "../../modules/Helper.jsx";
import MenuUpload from "../../modules/MenuUpload.jsx";

import en from '../../../../locales/en/translation.json';
import fr from '../../../../locales/fr/translation.json';

export default class UploadVideo_part3 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            marginTopValue: "21rem", // Valeur initiale de margin-top de la div "left" pour que l'element image soit respnsive
            sendLauch: false,
            user: null,

            openMoods:false,
            openMoments:false,
            openInfluences:false,
            openSonorites:false,
            openCreations:false,

            allow_Coms: true,
            allow_Share: true,
            allow_ViewLikes: true,
            allow_Repost: true,
            upload_forbiddenWords:"",

            // Checkbox Lists

            moods: null,
            upload_Moods: [],
            moments: null,
            upload_Moments: [],
            influences: null,
            upload_Influences: [],
            sonorites: null,
            upload_Sonorites: [],
            creation: null,
            upload_Creation: [],

            lang:'en',
            trad:en,
        };
    }

    componentDidMount() {
        if (JSON.parse(localStorage.getItem("user"))) {
            this.setState({
                user: JSON.parse(localStorage.getItem("user")),
            }, () => {
                let lang = this.state.user.user.language;
                if(lang === "fr"){
                    this.setState({lang:"fr",trad:fr});
                }
            });
        }

        document.title = "Upload Video - SOCIAL | Wonderplace";
        this.handleResize();
        window.addEventListener("resize", this.handleResize);
        bannerHeight();
        if (localStorage.getItem('storageType')) {
            let user = localStorage.getItem("user");
            if (user !== null) {
                this.setState({
                    user: JSON.parse(user),
                }, () => {
                    let video = localStorage.getItem('currentVideo');
                    video = JSON.parse(video);
                    let urlVideo = import.meta.env.VITE_BACKEND_URL + "/creator/getVideo";
                    axios.post(urlVideo,{
                        id:video.id,
                    },{
                        headers: {
                        Authorization: "Bearer " + this.state.user.token,
                    },
                    }).then( res => {
                        this.setState({
                            video: res.data,
                        },() => {
                            this.setState({
                                allow_Coms:this.state.video.infos.allow_Coms ? true: false,
                                allow_Repost:this.state.video.infos.allow_Repost? true : false,
                                allow_Share:this.state.video.infos.allow_Share? true : false,
                                allow_ViewLikes:this.state.video.infos.allow_ViewLikes? true : false,
                                upload_forbiddenWords:this.state.video.infos.upload_forbiddenWords,
                            });
                            this.stateCatReturn(this.state.video.cat);
                        })
                    }).catch(err => {
                        console.log(err);
                    })
                    let url = import.meta.env.VITE_BACKEND_URL + "/upload/getCategory";
                    axios.get(url, {
                        headers: {
                            Authorization: "Bearer " + this.state.user.token,
                        },
                    }).then(res => {
                        this.setState({
                            moods: res.data.moods,
                            moments: res.data.moments,
                            influences: res.data.influences,
                            sonorites: res.data.sonorites,
                            creation: res.data.creation,
                        });
                    }).catch(err => {
                        console.log(err);
                    })
                });
            } else {
                location.assign("/");
            }
        }
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }
    // Adapte "MarginTop" de la div "left"  afin de descendre en mobile
    handleResize = () => {
        const resolution = window.innerWidth;
        // Vérifier si la résolution est inférieure ou égale à 1280 pixels
        if (resolution < 1280) {
            // Calculer la nouvelle valeur "top" en fonction de la résolution
            const newMarginTopValue = 21 + resolution / 100; // Par exemple
            // Mettre à jour la valeur "MarginTop" dans le state
            this.setState({
                marginTopValue: newMarginTopValue + "rem",
            });
        } else {
            this.setState({
                marginTopValue: 0 + "rem",
            });
        }
    };
    handleChange = (event, id, type) => {
        let {upload_Moods, upload_Moments, upload_Influences, upload_Sonorites, upload_Creation,} = this.state;

        if (type == "moods") {
            if (event.target.checked) {
                upload_Moods.push(id);
                this.setState({upload_Moods})
            } else {
                let index = upload_Moods.indexOf(id);
                upload_Moods.splice(index, 1);
                this.setState({upload_Moods})
            }
        } else if (type == 'moments') {
            if (event.target.checked) {
                upload_Moments.push(id);
                this.setState({upload_Moments})
            } else {
                let index = upload_Moments.indexOf(id);
                upload_Moments.splice(index, 1);
                this.setState({upload_Moments})
            }
        } else if (type == 'influences') {
            if (event.target.checked) {
                upload_Influences.push(id);
                this.setState({upload_Influences})
            } else {
                let index = upload_Influences.indexOf(id);
                upload_Influences.splice(index, 1);
                this.setState({upload_Influences})
            }
        } else if (type == 'sonorites') {
            if (event.target.checked) {
                upload_Sonorites.push(id);
                this.setState({upload_Sonorites})
            } else {
                let index = upload_Sonorites.indexOf(id);
                upload_Sonorites.splice(index, 1);
                this.setState({upload_Sonorites})
            }
        } else if (type == 'creations') {
            if (event.target.checked) {
                upload_Creation.push(id);
                this.setState({upload_Creation})
            } else {
                let index = upload_Creation.indexOf(id);
                upload_Creation.splice(index, 1);
                this.setState({upload_Creation})
            }
        }
    }
    handleChangeSwitch = (e, type) => {
        if (type == "allow_Coms") {
            this.setState({allow_Coms: e.target.checked})
        } else if (type == "allow_Share") {
            this.setState({allow_Share: e.target.checked})
        } else if (type == "allow_Repost") {
            this.setState({allow_Repost: e.target.checked})
        } else if (type == "allow_ViewLikes") {
            this.setState({allow_ViewLikes: e.target.checked})
        }
    }
    handleChange_ForbiddenWords = (value) => {
        this.setState({upload_forbiddenWords: value,});
    };
    handleOpenCat = (type) => {
        if(type == "moods"){
            this.setState({openMoods:!this.state.openMoods});
        }else if(type == "moments"){
            this.setState({openMoments:!this.state.openMoments});
        }else if(type == "influences"){
            this.setState({openInfluences:!this.state.openInfluences});
        }else if(type == "sonorites"){
            this.setState({openSonorites:!this.state.openSonorites});
        }else if(type == "creations"){
            this.setState({openCreations:!this.state.openCreations});
        }
    }
    handleUploadContinue = () => {
        let {allow_Coms, allow_Share, allow_ViewLikes, allow_Repost, upload_forbiddenWords,video,upload_Moods,upload_Moments,upload_Influences,upload_Sonorites,upload_Creation} = this.state;
        const url = import.meta.env.VITE_BACKEND_URL + "/upload/savePart3";
        axios.post(url,{
            id:video.id,
            allow_Coms,
            allow_Share,
            allow_ViewLikes,
            allow_Repost,
            upload_forbiddenWords,
            upload_Moods,
            upload_Moments,
            upload_Influences,
            upload_Sonorites,
            upload_Creation,
        },{
            headers: {
                Authorization: "Bearer " + this.state.user.token,
            },
        }).then(res => {
            if(localStorage.getItem('editvideo')){
                localStorage.setItem('currentVideo',JSON.stringify(res.data));
                location.assign("/gestion-de-ma-page/upload-video-part4");
            }
        }).catch(err => {
            console.log(err);
        })

    };

    stateCatReturn(data){
        let newMoods = [];
        let newInflu = [];
        let newMom = [];
        let newCre = [];
        let newSono = [];
        data.moods.map(value =>{
            newMoods.push(value.moods_id);
        });
        data.influences.map(value => {
            newInflu.push(value.influences_id);
        });
        data.momentes.map(value => {
            newMom.push(value.moments_id);
        });
        data.creations.map(value => {
            newCre.push(value.creations_id);
        });
        data.sonorites.map(value => {
            newSono.push(value.sonorites_id);
        });
        this.setState({
            upload_Moods:newMoods,
            upload_Moments:newMom,
            upload_Influences:newInflu,
            upload_Sonorites:newSono,
            upload_Creation:newCre,
        });
    }

    render() {
        return (<div className="uploadVideoGlobal_Part3">
            <div className="uploadHeader">
                <h1>{this.state.trad.uploadHeader}</h1>
                <MenuUpload active={3} />
            </div>

            <div className="uploadContainer">
                <section
                    className="left"
                    style={{marginTop: this.state.marginTopValue}}
                >
                    <div className="notif">
                        <p className="description_text">{this.state.trad.pDescriptionText1}</p>
                        <div className="switchContainer">
                            <span className="yesNotif">{this.state.trad.spanYesNotif}</span>
                            <FormControlLabel
                                control={<MuiSwitch
                                    size="small"
                                    checked={this.state.allow_Coms}
                                    onChange={(e) => this.handleChangeSwitch(e, "allow_Coms")}
                                    name="allow_Coms"
                                />}
                            />
                            <span className="noNotif">{this.state.trad.spanNoNotif}</span>
                        </div>
                    </div>

                    <div className="notif">
                        <p className="description_text">{this.state.trad.pDescriptionText2}</p>
                        <div className="switchContainer">
                            <span className="yesNotif">{this.state.trad.spanYesNotif}</span>
                            <FormControlLabel
                                control={<MuiSwitch
                                    size="small"
                                    checked={this.state.allow_Share}
                                    onChange={(e) => this.handleChangeSwitch(e, "allow_Share")}
                                    name="allow_Share"
                                />}
                            />
                            <span className="noNotif">{this.state.trad.spanNoNotif}</span>
                        </div>
                    </div>

                    <div className="notif">
                        <p className="description_text">
                            {this.state.trad.pDescriptionText3}
                        </p>
                        <div className="switchContainer">
                            <span className="yesNotif">{this.state.trad.spanYesNotif}</span>
                            <FormControlLabel
                                control={<MuiSwitch
                                    size="small"
                                    checked={this.state.allow_ViewLikes}
                                    onChange={(e) => this.handleChangeSwitch(e, "allow_ViewLikes")}
                                    name="allow_ViewLikes"
                                />}
                            />
                            <span className="noNotif">{this.state.trad.spanNoNotif}</span>
                        </div>
                    </div>

                    <div className="notif">
                        <p className="description_text">{this.state.trad.pDescriptionText4}</p>
                        <div className="switchContainer">
                            <span className="yesNotif">{this.state.trad.spanYesNotif}</span>
                            <FormControlLabel
                                control={<MuiSwitch
                                    size="small"
                                    checked={this.state.allow_Repost}
                                    onChange={(e) => this.handleChangeSwitch(e, "allow_Repost")}
                                    name="allow_Repost"
                                />}
                            />
                            <span className="noNotif">{this.state.trad.spanNoNotif}</span>
                        </div>
                    </div>

                    <div className="upload_Area">
                        <label htmlFor="upload_forbiddenWordsArea">{this.state.trad.pDescriptionText5}</label>
                        <p>{this.state.trad.uploadTitle2}.</p>
                        <br/>
                        <textarea
                            name="upload_forbiddenWordsArea"
                            id="upload_forbiddenWordsArea"
                            cols="30"
                            rows="5"
                            maxLength="200"
                            onChange={(e) => this.handleChange_ForbiddenWords(e.target.value)}
                            value={this.state.upload_forbiddenWords}
                        />
                    </div>
                </section>

                <section className="right">
                    <div className="upload_VignetteSection">
                        <div className="upload_VignetteText">
                            {
                                this.state.video &&
                                <div className="vignette">
                                    <VideoUpload
                                        indexVideo={0}
                                        upload={false}
                                        id={this.state.video.id}
                                        video={import.meta.env.VITE_BACKEND_URL_IMG+this.state.video.path_nocompres}
                                    />
                                </div>
                            }
                        </div>
                    </div>
                </section>
            </div>

            <section className="bottom">
                <FormGroup className="moods">
                    <h4 onClick={() => this.handleOpenCat('moods')}>Moods <img src={arrow} alt="" className="arrow"/></h4>
                    <div className={`groupContainer ${this.state.openMoods ? "open" : "close"}`}>

                        {
                            this.state.moods !== null &&
                            this.state.moods.map((value, index) => (
                                <FormControlLabel
                                    key={index}
                                    control={<Checkbox
                                        icon={<RadioButtonUncheckedIcon/>}
                                        checkedIcon={<RadioButtonCheckedIcon/>}
                                        checked={this.state.upload_Moods.includes(value.id)}
                                        onChange={(e) => this.handleChange(e, value.id, "moods")}
                                        name={`upload_${index}`}
                                        sx={{
                                            "& .MuiSvgIcon-root": {
                                                color: "white", fontSize: "medium",
                                            },
                                        }}
                                    />}
                                    label={value.mood_name}
                                />
                            ))
                        }
                    </div>
                </FormGroup>

                <FormGroup className="moments">
                    <h4 onClick={() => this.handleOpenCat('moments')}>{this.state.trad.uploadTitle3} <img src={arrow} alt="" className="arrow"/></h4>
                    <div className={`groupContainer ${this.state.openMoments ? "open" : "close"}`}>
                        {
                            this.state.moments !== null &&
                            this.state.moments.map((value, index) => (
                                <FormControlLabel
                                    key={index}
                                    control={<Checkbox
                                        icon={<RadioButtonUncheckedIcon/>}
                                        checkedIcon={<RadioButtonCheckedIcon/>}
                                        checked={this.state.upload_Moments.includes(value.id)}
                                        onChange={(e) => this.handleChange(e, value.id, 'moments')}
                                        name="upload_Reveil"
                                        sx={{
                                            "& .MuiSvgIcon-root": {
                                                color: "white", fontSize: "medium",
                                            },
                                        }}
                                    />}
                                    label={value.moment_name}
                                />
                            ))
                        }
                    </div>
                </FormGroup>

                <FormGroup className="influences">
                    <h4 onClick={() => this.handleOpenCat('influences')}>{this.state.trad.uploadTitle4} <img src={arrow} alt="" className="arrow"/></h4>
                    <div className={`groupContainer ${this.state.openInfluences ? "open" : "close"}`}>
                        {
                            this.state.influences !== null &&
                            this.state.influences.map((value, index) => (
                                <FormControlLabel
                                    key={index}
                                    control={<Checkbox
                                        icon={<RadioButtonUncheckedIcon/>}
                                        checkedIcon={<RadioButtonCheckedIcon/>}
                                        checked={this.state.upload_Influences.includes(value.id)}
                                        onChange={(e) => this.handleChange(e, value.id, "influences")}
                                        name="upload_Sombre"
                                        sx={{
                                            "& .MuiSvgIcon-root": {
                                                color: "white", fontSize: "medium",
                                            },
                                        }}
                                    />}
                                    label={value.influence_name}
                                />
                            ))
                        }
                    </div>
                </FormGroup>

                <FormGroup className="sonorites">
                    <h4 onClick={() => this.handleOpenCat('sonorites')}>{this.state.trad.uploadTitle5} <img src={arrow} alt="" className="arrow"/></h4>
                    <div className={`groupContainer ${this.state.openSonorites ? "open" : "close"}`}>
                        {
                            this.state.sonorites !== null &&
                            this.state.sonorites.map((value, index) => (
                                <FormControlLabel
                                    key={index}
                                    control={<Checkbox
                                        icon={<RadioButtonUncheckedIcon/>}
                                        checkedIcon={<RadioButtonCheckedIcon/>}
                                        checked={this.state.upload_Sonorites.includes(value.id)}
                                        onChange={(e) => this.handleChange(e, value.id, 'sonorites')}
                                        name="upload_Pop"
                                        sx={{
                                            "& .MuiSvgIcon-root": {
                                                color: "white", fontSize: "medium",
                                            },
                                        }}
                                    />}
                                    label={value.sonorite_name}
                                />
                            ))
                        }
                    </div>
                </FormGroup>

                <FormGroup className="creations">
                    <h4 onClick={() => this.handleOpenCat('creations')}>{this.state.trad.uploadTitle6} <img src={arrow} alt="" className="arrow"/></h4>
                    <div className={`groupContainer ${this.state.openCreations ? "open" : "close"}`}>
                        {
                            this.state.creation !== null &&
                            this.state.creation.map((value, index) => (
                                <FormControlLabel
                                    key={index}
                                    control={<Checkbox
                                        icon={<RadioButtonUncheckedIcon/>}
                                        checkedIcon={<RadioButtonCheckedIcon/>}
                                        checked={this.state.upload_Creation.includes(value.id)}
                                        onChange={(e) => this.handleChange(e, value.id, "creations")}
                                        name="upload_Clips"
                                        sx={{
                                            "& .MuiSvgIcon-root": {
                                                color: "white", fontSize: "medium",
                                            },
                                        }}
                                    />}
                                    label={value.creation_name}
                                />
                            ))
                        }
                    </div>
                </FormGroup>
            </section>

            <div className="navButtons">
                <NavigateBackButton/>

                <div className="form_submit_container">
                    {this.state.sendLauch ? (<div className="lds-ring">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>) : (<div className="form_submit">
                        <img
                            src={this.state.lang === "fr" ? continuerButton : changeValidButtonen}
                            alt="continuer"
                            className="continuer"
                            onClick={() => this.handleUploadContinue()}
                        />
                    </div>)}
                </div>
            </div>
        </div>);
    }
}