import React, {useState, useEffect, useRef} from "react";
// import PropTypes from "prop-types";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import axios from "axios";

const VideoJS = React.forwardRef((props, ref) => {
    const videoRef = useRef(null);
    const playerRef = useRef(null);
    const {options, onReady} = props;
    const [indexVideo, setIndexVideo] = useState(props.indexVideo);
    const [tabBar, setTabBar] = useState();
    const [buyTab, setBuyTab] = useState();
    const [bought, setBought] = useState();
    const [currentAchat, setCurrentAchat] = useState(0);
    const [id, setId] = useState(props.id);
    const [axiosSent, setAxiosSent] = useState(false);
    const [secondsElapsed, setSecondsElapsed] = useState(0);

    const token = props.token;
    const nft = props.nft;
    const intervalIdRef = useRef();


    const handleNextVideo = async () => {
        const player = playerRef.current;
        player.pause();

        let newId = videoRef.current.parentElement.parentElement.parentElement.id.split("_");
        let newIdNb = parseInt(newId[1]) + 1;
        newId = `video_${newIdNb}`;

        const nextVideo = document.getElementById(newId);
        const yOffset = -140;
        const y = nextVideo.getBoundingClientRect().top + window.pageYOffset + yOffset;

        // Faites défiler la page jusqu'à l'élément vidéo suivant
        //nextVideo.scrollIntoView({behavior: "smooth"});
        window.scrollTo({top: y, behavior: 'smooth'});
        props.handleCallback(indexVideo);

        // videojs.log(nextVideo);
    };
    const handleFullScreen = () => {
        const player = playerRef.current;
        player.pause();
        let time = player.cache_.currentTime;
        let videofull = document.querySelector(".homepageGlobal");
        if (videofull) {
            // Passer en plein écran
            if (videofull.requestFullscreen) {
                videofull.requestFullscreen();
            } else if (videofull.mozRequestFullScreen) { // Firefox
                videofull.mozRequestFullScreen();
            } else if (videofull.webkitRequestFullscreen) { // Chrome, Safari et Opera
                videofull.webkitRequestFullscreen();
            } else if (videofull.msRequestFullscreen) { // IE/Edge
                videofull.msRequestFullscreen();
            }
            // Changement de l'orientation de l'écran en paysage
            const isMobileOrTablet = () =>
                typeof window.orientation !== "undefined"
                || navigator.userAgent.indexOf('IEMobile') !== -1
                || 'ontouchstart' in window;
            if (isMobileOrTablet()) {
                if (screen.orientation && screen.orientation.lock) {
                    screen.orientation.lock('landscape').catch((error) => {
                        console.error('Could not lock screen orientation', error);
                    });
                }
            }
            props.handleCallBackFullScreen(true, indexVideo, time)
        }
    }
    const handleAcheter = (j) => {
        console.log('acheter ' + currentAchat)
        let select = `#video_${indexVideo} .tabBarBuy button`;

    }
    const handleJumpToTime = async (time, classbtn, index) => {
        let select = `#video_${indexVideo} .tabBarBuy button`;
        let btn = document.querySelectorAll(select);
        await allbtn.map((value, index) => {
            value.childNodes[0].childNodes[0].classList.add('close')
            value.childNodes[0].childNodes[0].classList.remove('open')
        })
        const player = playerRef.current;
        if (classbtn == "avendre") {
            //let btn = document.querySelectorAll(select);
            btn[index].childNodes[0].classList.remove('close')
            btn[index].childNodes[0].classList.add('open')
            setCurrentAchat(time);
            player.currentTime(time);
        } else {
            player.currentTime(time);
        }
    }
    const handleCreateBarNft = () => {
        let tab = [];

        for (let i = 0; i < props.duration; i += 5) {
            let j = i / 5;
            let classBtn = "indispo";
            buyTab.map((vbuy) => {
                if (vbuy.startTime === i) {
                    classBtn = "vendu";
                }
            })
            bought.map((vboug) => {
                if (vboug.startTime === i) {
                    classBtn = "avendre";
                }
            })
            tab.push(
                <button
                    className={classBtn}
                    key={i}
                    onClick={() => handleJumpToTime(i, classBtn, j)}
                >
                    <button className="bullMenu close" onClick={() => handleAcheter(j)}>Acheter</button>
                </button>
            );
        }
        setTabBar(tab);
    };

    useEffect(() => {
        // BARE LOAD BUY ET SELECT A DECOMMENTER
        //handleCreateBarNft();
        // Make sure Video.js player is only initialized once
        if (!playerRef.current) {
            // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode.
            const videoElement = document.createElement("video-js");
            videoElement.classList.add("vjs-big-play-centered");
            videoRef.current.appendChild(videoElement);

            let isTimeUpdated = false;
            const player = (playerRef.current = videojs(videoElement, options, () => {
                //videojs.log("player is ready");
                onReady && onReady(player);

            }));
            player.on('canplay', function () {
                if (!isTimeUpdated) {
                    this.currentTime(options.currentTime);
                    isTimeUpdated = true;
                }

            });

            player.controlBar.addChild(
                "button",
                {
                    text: "FullScreen",
                    el: videojs.dom.createEl("FullButton", {
                        className: "vjs-fullscreen-control",
                        title: "FullScreen",
                        role: "button",
                    }),
                    clickHandler: handleFullScreen,
                },
                // Add the RestartButton to the control bar and specify the display order
                player.controlBar.children().length - 0
            );

            // Add a restart button to the control bar
            player.controlBar.addChild(
                "button",
                {
                    text: "Restart",
                    el: videojs.dom.createEl("RestartButton", {
                        className: "vjs-icon-restart",
                        title: "Restart",
                        role: "button",
                    }),
                    clickHandler: function () {
                        player.currentTime(0);
                        player.play().catch(function (erreur) {

                        });
                        ;
                    },
                },

                // Add the RestartButton to the control bar and specify the display order
                player.controlBar.children().length - 0
            );

            // Add a NextVideo button to the control bar
            player.controlBar.addChild(
                "button",
                {
                    text: "NextVideo",
                    el: videojs.dom.createEl("NextVideoButton", {
                        className: "vjs-icon-NextVideo",
                        title: "NextVideo",
                        role: "button",
                    }),
                    clickHandler: handleNextVideo,
                },

                // Add the NextVideoButton to the control bar and specify the display order
                player.controlBar.children().length - 0
            );

            // You could update an existing player in the `else` block here
            // on prop change, for example:
        } else {
            const player = playerRef.current;

            player.autoplay(options.autoplay);
            player.src(options.sources);
            //console.log(player.options_.sources.videosBought);
            //console.log(player.options_.sources.videosToBuy);
            setBuyTab(player.options_.sources.videosToBuy);
            setBought(player.options_.sources.videosBought);
        }
    }, [options, videoRef, currentAchat]);

    useEffect(() => {

        const player = playerRef.current;

        player.on('play', function() {
            intervalIdRef.current = setInterval(() => {
                if (!axiosSent) {
                    setSecondsElapsed(secondsElapsed => secondsElapsed + 1);
                } else {
                    clearInterval(intervalIdRef.current);
                }
            }, 1000);
        });
        player.on('pause', function() {
            clearInterval(intervalIdRef.current);
        });

        return () => {
            clearInterval(intervalIdRef.current);
        };
    }, []);
    useEffect(() => {
        if(secondsElapsed > 30 && !axiosSent){
            let url = import.meta.env.VITE_BACKEND_URL + "/videos/viewUpdate";
            axios.post(url, {
                token
            }).then((response) => {
                setAxiosSent(true);
                clearInterval(intervalIdRef.current);
            }).catch((error) => {
                console.log(error);
            });
        }
    }, [secondsElapsed,axiosSent]);
    // Dispose the Video.js player when the functional component unmounts
    useEffect(() => {
        const player = playerRef.current;
        return () => {
            if (player && !player.isDisposed()) {
                player.dispose();
                playerRef.current = null;
            }
        };
    }, [playerRef]);
    useEffect(() => {
        const player = playerRef.current;

        const observer = new IntersectionObserver(
            entries => {
                // entries[0] est le premier élément observé
                if (entries[0].isIntersecting) {
                    // Si l'élément est visible, lancez la vidéo
                    player.play().catch(function (erreur) {

                    });
                    props.callbackIndexVideo(props.indexID);
                } else {
                    // Si l'élément n'est pas visible, mettez la vidéo en pause
                    if (player) { // Ajout de cette vérification
                        player.pause();
                    }
                }
            },
            {
                // Définir le pourcentage de visibilité pour déclencher l'observer
                threshold: 0.5
            }
        );

        // Commencer à observer l'élément vidéo
        observer.observe(videoRef.current);

        // Nettoyer après utilisation
        return () => {
            if (videoRef.current) {
                observer.unobserve(videoRef.current);
            }
        };
    }, []);
    return (
        <div data-vjs-player className="video-js-player">
            <div ref={videoRef} className="video-js"/>
            <div className="barBuy">
                <div className="tabBarBuy close">
                    {
                        screen.width > 1280 ?
                            nft &&
                            <a onClick={() => localStorage.setItem('isNft', true)} target="_blank"
                               className="ntf_btn_show" href={`/video/${token}`}>Acheter un instant</a>
                            :
                            nft &&
                            <a onClick={() => localStorage.setItem('isNft', true)} className="ntf_btn_show"
                               href={`/video/${token}`}>Acheter un instant</a>
                    }
                </div>
            </div>
        </div>
    );
});

// VideoJS.propTypes = {
//   options: PropTypes.object.isRequired,
//   onReady: PropTypes.func.isRequired,
// };

export default VideoJS;
