import React, {useContext, useEffect, useState} from 'react';
import VideoDataContext from '../../VideoDataContext';
import axios from 'axios';

import continu from '../../../assets/logos/continuerButton.svg';
import cross from '../../../assets/logos/crossIcon.svg';
import continuEn from '../../../assets/logos/en/continuerButtonen.svg';
import ondeSmall from "../../../assets/wallet/ondeSmall.svg";
import send from "../../../assets/wallet/send.svg";
import {parseInt} from "lodash";

const ShopBuy = (props) => {
    const {videoData} = useContext(VideoDataContext);
    const { globalCountdown,setGlobalCountdown } = useContext(VideoDataContext);
    const [user, setUser] = useState(null);
    const [localVideoData, setLocalVideoData] = useState(videoData);
    const [openShop, setOpenShop] = useState(false);
    const [lang, setLang] = useState('en');
    const [message, setMessage] = useState('');
    const [promo, setPromo] = useState('');
    const [montantPromo, setMontantPromo] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [totalPriceInMatic, setTotalPriceInMatic] = useState(0);
    const [intervalId, setIntervalId] = useState(null);

    useEffect(() => {
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
            setUser(JSON.parse(storedUser));
        }
    }, []);
    useEffect(() => {
        setOpenShop(props.openShop);
        setLocalVideoData(videoData);
        if (videoData.length > 0) {
            setLang(videoData[0].lang);
            let newEuro = 0;
            let newMatic = 0;
            videoData.map((video) => {
                newEuro += parseFloat(video.price);
                newMatic += parseFloat(video.priceInMatic);
            });
            setTotalPrice(newEuro);
            setTotalPriceInMatic(newMatic);
        }

        // Si une nouvelle vidéo est ajoutée, augmentez le compteur global de 15 minutes
        if (videoData.length > localVideoData.length) {
            setGlobalCountdown(prevCountdown => prevCountdown + 15 * 60 * 1000); // 15 minutes en millisecondes
        }

    }, [videoData, props.openShop]);
    //useEffect(() => {
    //    if (videoData.length > 0) {
    //        const interval = setInterval(() => {
    //            setGlobalCountdown(prevCountdown => {
    //                if (prevCountdown > 0) {
    //                    return prevCountdown - 1000;
    //                } else {
    //                    if (user) {
    //                        // Faire une requête axios
    //                        const url = import.meta.env.VITE_BACKEND_URL + "/gerance/resetShopBuy";
    //                        axios.post(url, {
    //                            videoData: videoData,
//
    //                        }, {
    //                            headers: {
    //                                Authorization: "Bearer " + user.token,
    //                            },
    //                        }).then(response => {
    //                            if (response.data) {
    //                                setLocalVideoData([]);
    //                                clearInterval(interval)
    //                                setTotalPrice(0);
    //                                setTotalPriceInMatic(0);
    //                            }
    //                        })
    //                            .catch(error => {
    //                                console.error('Il y avait une erreur!', error);
    //                            });
//
    //                        return 0;
    //                    }
    //                }
    //            });
    //        }, 1000);
    //        setIntervalId(interval);
    //        return () => clearInterval(interval);
    //    }
    //}, [user, videoData]);
    const handleAddPromo = () => {
        console.log(promo);
        axios.post(import.meta.env.VITE_BACKEND_URL + "/gerance/promo", {
            promo: promo,
        }, {
            headers: {
                Authorization: "Bearer " + user.token,
            },
        }).then(response => {
            if (response.data !== null) {
                let reduction = parseInt(response.data.reduction);
                setMontantPromo(reduction);
                reduction = (100 - reduction) / 100;
                setTotalPrice(totalPrice * reduction);
                setTotalPriceInMatic(totalPriceInMatic * reduction);
                setPromo('');
            }
        }).catch(error => {
            console.error('Il y avait une erreur!', error);
        })
    }
    const handlePaiement = () => {
        console.log('Paiement');
    }
    const removeVideo = (index) => {
        const newVideoData = [...localVideoData];
        newVideoData.splice(index, 1);
        setLocalVideoData(newVideoData);

        if (newVideoData.length === 0 && intervalId) {
            clearInterval(intervalId);
            setGlobalCountdown(0);
        }
    };
    const handleChangeMessage = (value, i) => {
        setLocalVideoData(prevVideoData => {
            const newVideoData = [...prevVideoData];
            newVideoData[i].message = value;
            return newVideoData;
        });
    };
    function formatTime(ms) {
        const totalSeconds = Math.floor(ms / 1000);
        const totalMinutes = Math.floor(totalSeconds / 60);
        const totalHours = Math.floor(totalMinutes / 60);

        const seconds = totalSeconds % 60;
        const minutes = totalMinutes % 60;
        const hours = totalHours;

        return `${hours}h ${minutes}m ${seconds}s`;
    }

    return (
        openShop && videoData.length > 0 &&
        <div className="block_panier">
            <div className="headerShop">
                <h2>Votre panier</h2>
                <p className="timer_nft">{formatTime(globalCountdown)}</p>
                <p>{localVideoData.length} items</p>
            </div>
            <div className="bodyShop">
                {
                    localVideoData.length > 0 &&
                    localVideoData.map((video, index) => (
                        <div key={index} className="lineShop">
                            <div className="line_internal">
                                <div className="removeNtf" onClick={() => removeVideo(index)}><img src={cross} alt=""/>
                                </div>
                                <div className="video_img">
                                    <img src={video.poster}/>
                                    <p className="time">{parseInt(video.endTime) - parseInt(video.startTime)}s.</p>
                                    <div className="onde_small">
                                        <img src={ondeSmall} alt=""/>
                                    </div>
                                </div>
                                <div className="creator">
                                    <p>{video.title}</p>
                                    <p>{video.creator}</p>
                                </div>
                                <div className="price">
                                    <p>{video.price}€</p>
                                    <p>{video.priceInMatic} MATIC</p>
                                </div>
                            </div>
                            <div className="line_legend">
                                <label htmlFor="message">Ajouter une légende</label>
                                <input value={video.message}
                                       onChange={(e) => handleChangeMessage(e.target.value, index)} type="text"
                                       name="message"/>
                            </div>
                        </div>
                    ))
                }
            </div>
            <div className="footerShop">
                <div className="promo_code">
                    <label htmlFor="promo">Code Promo</label>
                    <input type="text" name="promo" value={promo} onChange={(e) => setPromo(e.target.value)}/>
                    <div className="promo_btn" onClick={handleAddPromo}>
                        <img src={send} alt=""/>
                    </div>
                </div>
                {
                    montantPromo > 0 &&
                    <div className="promo_applied">
                        <p>Code Promo appliqué</p>
                        <p>-{montantPromo}%</p>
                    </div>
                }
                <div className="total_price">
                    <div className="left">
                        <p>Prix Total</p>

                    </div>
                    <div className="right">
                        <p>{totalPrice} €</p>
                        <p>{totalPriceInMatic} MATIC</p>
                    </div>
                </div>
                <button onClick={handlePaiement}>
                    <img src={lang === "fr" ? continu : continuEn}/>
                </button>
            </div>
        </div>
    );
}

export default ShopBuy;
