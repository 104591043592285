import React from "react";
import axios from "axios";
import VideoPlayer from "../modules/VideoPlayer.jsx";
import SmallModal from "../modules/SmallModal.jsx";


import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import background from "../../../assets/backgrounds/backgroundCompte.jpg";
import {bannerSelect} from "../modules/Helper.jsx";
import VideoPlayerFull from "../modules/VideoPlayerFull.jsx";
import fr from "../../../locales/fr/translation.json";
import en from "../../../locales/en/translation.json";
import videoBig from "../../../assets/backgrounds/1_big.png";

export default class PlaylistPlayer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            videos: [],
            user: null,
            page: 1,
            type: null,
            lang: 'en',
            trad: en,
            nbIndexVideo:2,
            isFirst:true,
            load:true,
        }
    }
    async componentDidMount() {
        let path = location.pathname;
        path = path.split('/');
        this.setState({type: path[2]});
        let user = localStorage.getItem('user');
        if (user !== null) {
            let userConnect = JSON.parse(user);
            await this.setState({
                user: userConnect,
            }, () => {
                let lang = this.state.user.user.language;
                if (lang === "fr") {
                    this.setState({lang: "fr", trad: fr});
                }
                this.setState({load:true});
                let url = import.meta.env.VITE_BACKEND_URL + "/playlist/load/video?page=" + this.state.page;
                if (path[2] === "like") {
                    axios.post(url, {
                        type: path[2],
                        id: path[3]
                    }, {
                        headers: {
                            Authorization: 'Bearer ' + this.state.user.token
                        }
                    }).then(res => {
                        this.loadDataState(res.data, false,0);
                        this.setState({load:false});
                    }).catch(err => {
                        console.log(err);
                    })
                } else if (path[2] === "reposts") {
                    axios.post(url, {
                        type: path[2],
                        id: path[3]
                    }, {
                        headers: {
                            Authorization: 'Bearer ' + this.state.user.token
                        }
                    }).then(res => {
                        this.loadDataState(res.data, false,0);
                        this.setState({load:false});
                    }).catch(err => {
                        console.log(err);
                    })
                } else {
                    axios.post(url, {
                        type: "myPlaylist",
                        id: path[2]
                    }, {
                        headers: {
                            Authorization: 'Bearer ' + this.state.user.token
                        }
                    }).then(res => {
                        this.loadDataState(res.data, false,0);
                        this.setState({load:false});
                        this.setState({
                            name: res.data[0].playlistName,
                            desc: res.data[0].playlistDesc,
                            img: res.data[0].playlistImg,
                        }, () => {
                            let urlImage = "";
                            if (this.state.img !== null) {
                                urlImage = `url(${import.meta.env.VITE_BACKEND_URL_IMG + this.state.img})`;
                            } else {
                                urlImage = `url(${background})`;
                            }
                            let content = document.querySelector('.content');
                            bannerSelect(urlImage);
                            content.classList.add('pageCreateur');

                            this.setState({
                                styleImageBackGround: urlImage
                            })
                        })
                    }).catch(err => {
                        console.log(err);
                    })
                }
            })
        } else {
            location.assign('/');
        }

    }
    loadDataState = (listvideos, load,indexVideo) => {
        let videos = [];
        if (load) {
            videos = this.state.videos;
        }
        listvideos.map((value, index) => {

            let video = {};
            video.id = value.id;
            video.isPlay = false;
            video.src = import.meta.env.VITE_BACKEND_URL_IMG + value.path_nocompres;
            video.type = "video/mp4";
            video.poster = value.miniature != null ? import.meta.env.VITE_BACKEND_URL_IMG + value.miniature : videoBig;
            video.artistPhoto = import.meta.env.VITE_BACKEND_URL_IMG + value.utilisateur.avatar;
            video.artistName = `${value.utilisateur.lastname !== null ? value.utilisateur.lastname : ""} ${value.utilisateur.firstname !== null ? value.utilisateur.firstname : ""}`;
            video.artistTitle = value.utilisateur.nom_artiste;
            video.artistDescription = value.utilisateur.biographie;
            video.artistMoreDetails = "Plus d’informations…";
            video.worldArtiste = value.info ? value.info.upload_artistWord : "";
            video.otherInfo = value.info ? value.info.upload_otherInfos.replace(/\n/g, '<br />') : "";
            video.droit = value.droits ? value.droits : [];
            video.hasLiked = value.hasLiked;
            video.hasDisLiked = value.hasDisLiked;
            video.isLiked = value.isLiked;
            video.title = value.title;
            video.isCommented = value.isCom;
            video.isBoorkmarked = value.book;
            video.isBooked = value.booked;
            video.isFollowed = value.share;
            video.genreIcon = [];
            video.token = value.token;
            video.userId = value.utilisateur_id;
            //video.videosToBuy = []
            video.videosToBuy = [
                {startTime: 0, endTime: 5, label: "Disponible"},
                {startTime: 20, endTime: 25, label: "Disponible"},
                {startTime: 50, endTime: 55, label: "Disponible"},
            ];
            //video.videosBought = []
            video.videosBought = [
                {startTime: 40, endTime: 45, label: "Indisponible"},
                {startTime: 130, endTime: 135, label: "Indisponible"},
            ];
            videos.push(video);
        });
        if(this.state.isFirst){
            this.setState({videos,isFirst: false});
        }else{
            this.setState(prevState => {
                const newVideos = prevState.videos.map((video, index) => {
                    return {...video, isPlay: index === indexVideo};
                });

                return {
                    videos: newVideos,
                };
            });
        }
    }
    handleCallBackPlay = (id) => {
        const {videos} = this.state;
        const updatedVideos = videos.map((value, index) => ({
            ...value,
            isPlay: index === id,
        }));
        this.setState({videos: updatedVideos});
    }
    handleFullScreenChangeVideo = (index) => {
        this.setState({indexVideo: index});
    }
    callBackFreshPage = () => {
        window.location.reload(true);
    }
    handleCallBackFullScreen = (value, indexVideo, time) => {
        if (value) {
            this.setState({full: value, indexVideo: indexVideo, currentTime: parseInt(time)});
        } else {
            this.setState({full: value, indexVideo: indexVideo, currentTime: parseInt(time)}, () => {
                //setTimeout(() => {
                //    let video = document.getElementById('video_' + indexVideo);
                //    //const heightVideo = video.getBoundingClientRect().top - 50;
                //    //console.log(heightVideo)
                //    //window.scroll(0, heightVideo);
                //}, 1000)
            });
        }
    }
    callbackIndexVideo = (indexVideo) => {
        let {videos, page} = this.state;
        let path = location.pathname;
        path = path.split('/');
        let nb = videos.length - 2;
        if (indexVideo == nb) {
            page++;
            this.setState({load:true});
            let url = import.meta.env.VITE_BACKEND_URL + "/playlist/load/video?page=" + page;
            if (path[2] === "like") {
                axios.post(url, {
                    type: path[2],
                    id: path[3]
                }, {
                    headers: {
                        Authorization: 'Bearer ' + this.state.user.token
                    }
                }).then(res => {
                    this.setState({load:false});
                    this.setState({page}, () => {
                        this.loadDataState(res.data, true,indexVideo);
                    })
                }).catch(err => {
                    console.log(err);
                })
            } else if (path[2] === "reposts") {
                axios.post(url, {
                    type: path[2],
                    id: path[3]
                }, {
                    headers: {
                        Authorization: 'Bearer ' + this.state.user.token
                    }
                }).then(res => {
                    this.setState({load:false});
                    this.setState({page}, () => {
                        this.loadDataState(res.data, true,indexVideo);
                    })
                }).catch(err => {
                    console.log(err);
                })
            } else {
                axios.post(url, {
                    type: "myPlaylist",
                    id: path[2]
                }, {
                    headers: {
                        Authorization: 'Bearer ' + this.state.user.token
                    }
                }).then(res => {
                    this.setState({load:false});
                    this.setState({page}, () => {
                        this.loadDataState(res.data, true,indexVideo);
                    })
                }).catch(err => {
                    console.log(err);
                })
            }

        }
    }
    handleSendBug = (type, id) => {
        let send = [];
        if (type === "bug") {
            send.push(type);
            send.push("video");
            send.push(id);
            this.setState({send, openModalContenu: false, openModalBug: true});
        } else if (type === "contenu") {
            send.push(type);
            send.push("video");
            send.push(id);
            this.setState({send, openModalContenu: true, openModalBug: false});
        }
        if (type === "bug") {
            this.setState({send, currentIdVideo: id, openModalBug: true});
        } else {
            this.setState({send, currentIdVideo: id, openModalContenu: true});
        }
    }
    callbackSend = (message) => {
        const {send, user} = this.state;
        let url = import.meta.env.VITE_BACKEND_URL + "/simple/send";
        axios.post(url, {
            send,
            message: message,
            user
        }).then(res => {
            if (res.data) {
                this.handleCloseModal();
                this.setState({
                    typeFlash: 'success',
                    flashMessage: "Votre demande a bien été pris en compte.",
                    send: null
                }, () => {
                    setTimeout(() => {
                        this.setState({flashMessage: ""})
                    }, 3000)
                });
            }
        }).catch(err => {
            this.setState({
                openModalContenu: false,
                openModalBug: false,
                typeFlash: 'warning',
                flashMessage: "Un problème est survenu pendant l’envoi de votre demande, veuillez réessayer plus tard."
            }, () => {
                setTimeout(() => {
                    this.setState({flashMessage: ""})
                }, 3000)
            });
        })
    }
    handleCloseModal = () => {
        this.setState({send: null, currentIdVideo: null, openModalBug: false, openModalContenu: false});
    }

    render() {
        return (
            <>
                <div className="content">
                    <div className={`homepageGlobal ${this.state.videos[0] ? '' : 'empty'} playlistPlayer`}>
                        {
                            this.state.videos[0] ?
                                this.state.type === "like" ?
                                    <h1>Mes vidéos aimées / Loved Content</h1>
                                    :
                                    this.state.type === "reposts" ?
                                        <h1>Mes vidéos partagées / My Shared Videos</h1>
                                        :
                                        <h1>{this.state.name}, {this.state.desc}</h1>
                                : null

                        }
                            {this.state.full ?
                                <div className="fullscreen">
                                    <VideoPlayerFull
                                        indexVideo={this.state.indexVideo}
                                        id={this.state.indexVideo}
                                        key={this.state.indexVideo}
                                        video={this.state.videos[this.state.indexVideo]}
                                        infos={false}
                                        handleCallBackPlay={this.handleCallBackPlay}
                                        handleCallBackFullScreen={this.handleCallBackFullScreen}
                                        handleFullScreenChangeVideo={this.handleFullScreenChangeVideo}
                                        currentTime={this.state.currentTime}
                                    />
                                </div>
                                :
                                this.state.videos[0] ?
                                    this.state.videos.map((value, index) => (
                                        <VideoPlayer
                                            indexVideo={index}
                                            id={`video_${index}`}
                                            key={value.id}
                                            video={value}
                                            videosToBuy={value}
                                            videosBought={value}
                                            infos={false}
                                            handleCallBackPlay={this.handleCallBackPlay}
                                            handleCallBackFullScreen={this.handleCallBackFullScreen}
                                            currentTime={this.state.currentTime}
                                            callbackIndexVideo={this.callbackIndexVideo}
                                            callBackFreshPage={this.callBackFreshPage}
                                            handleSendBug={this.handleSendBug}
                                            user={this.state.user}
                                            full={this.state.full}
                                            trad={this.state.trad}
                                        />
                                    ))
                                    :
                                    <div className="loading-wave">
                                        <div className="loading-bar"></div>
                                        <div className="loading-bar"></div>
                                        <div className="loading-bar"></div>
                                        <div className="loading-bar"></div>
                                    </div>
                            }
                        {
                            this.state.load &&
                            <div className="loading-wave">
                                <div className="loading-bar"></div>
                                <div className="loading-bar"></div>
                                <div className="loading-bar"></div>
                                <div className="loading-bar"></div>
                            </div>
                        }
                    </div>
                </div>
                {
                    this.state.openModalBug &&
                    <SmallModal title="Avertir d’un bug" label="Décrire le bug" handleCloseModal={this.handleCloseModal}
                                callbackSend={this.callbackSend}/>
                }
                {
                    this.state.openModalContenu &&
                    <SmallModal title="SIGNALER UN CONTENU" label="Décrire le signalement"
                                handleCloseModal={this.handleCloseModal} callbackSend={this.callbackSend}/>
                }
            </>
        )
    }

}
