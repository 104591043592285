import React, {Component} from "react";
import {Link} from "react-router-dom";
import axios from "axios";
import NavigateBackButton from "../../modules/NavigateBackButton.jsx";
import continuerButton from "../../../../assets/logos/changeValidButton.svg";
import changeValidButtonen from "../../../../assets/logos/en/changeValidButtonen.svg";

import uploadVideoIcon from "../../../../assets/logos/noun-upload-3778990.svg";
import vignette1 from "../../../../assets/images/vignette1.jpg";
import {bannerHeight} from "../../modules/Helper.jsx";

import en from '../../../../locales/en/translation.json';
import fr from '../../../../locales/fr/translation.json';
import MenuUpload from "../../modules/MenuUpload.jsx";
import VideoUpload from "../../modules/VideoUpload.jsx";

export default class UploadVideo_part6 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            marginTopValue: "18rem", // Valeur initiale de margin-top de la div "left" pour que l'element image soit respnsive
            sendLauch: false,
            user: null,
            lang: 'en',
            trad: en,
            datepublication: "",
            datenft: "",
            politique: false,
        };
    }
    componentDidMount() {
        document.title = "Upload Video - PUBLICATION | Wonderplace";
        this.handleResize();
        window.addEventListener("resize", this.handleResize);
        let user = localStorage.getItem("user");
        bannerHeight();
        if (JSON.parse(localStorage.getItem("user"))) {
            this.setState({
                user: JSON.parse(localStorage.getItem("user")),
            }, () => {
                let lang = this.state.user.user.language;
                if (lang === "fr") {
                    this.setState({lang: "fr", trad: fr});
                }
            });
        } else {
            location.assign("/");
        }
        if (localStorage.getItem('storageType')) {
            let user = localStorage.getItem("user");
            if (user !== null) {
                this.setState({
                    user: JSON.parse(user),
                }, () => {
                    let video = localStorage.getItem('currentVideo');
                    video = JSON.parse(video);
                    console.log(video);
                    let urlVideo = import.meta.env.VITE_BACKEND_URL + "/creator/getVideo";
                    axios.post(urlVideo, {
                        id: video.id,
                    }, {
                        headers: {
                            Authorization: "Bearer " + this.state.user.token,
                        },
                    }).then(res => {
                        this.setState({
                            video: res.data,
                        });
                    }).catch(err => {
                        console.log(err);
                    });
                });
            } else {
                location.assign("/");
            }
        }
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }
    handleResize = () => {
        const resolution = window.innerWidth;

        if (resolution < 1280) {
            const newMarginTopValue = 18 + resolution / 100;
            this.setState({
                marginTopValue: newMarginTopValue + "rem",
            });
        } else {
            this.setState({
                marginTopValue: 0 + "rem",
            });
        }
    };
    handleUploadContinue = (e) => {
        e.preventDefault();
        const {video, datepublication, datenft, politique} = this.state;
        if (datepublication === "" || datenft === "" || !politique) {
            alert("Veuillez remplir tous les champs");
        } else {
            this.setState({sendLauch: true});
            let url = import.meta.env.VITE_BACKEND_URL + "/upload/savePart6";
            axios.post(url, {
                id: video.id,
                datepublication: datepublication,
                datenft: datenft,
                politique: politique,
            }, {
                headers: {
                    Authorization: "Bearer " + this.state.user.token,
                },
            }).then(res => {
                this.setState({sendLauch: false});
                console.log(res.data);
                //localStorage.removeItem('currentVideo');
                //location.assign("/creator/uploadVideo_part7");
                localStorage.removeItem('editvideo');
                localStorage.removeItem('currentVideo');
                localStorage.setItem('message',"success:Votre vidéo a bien été modifié.");
                location.assign("/gestion-de-ma-page");
            }).catch(err => {
                this.setState({sendLauch: false});
                console.log(err);
            });
        }
    };

    render() {
        return (
            <div className="uploadVideoGlobal_Part5">
                <div className="uploadHeader">
                    <h1>{this.state.trad.uploadHeader}</h1>
                    <MenuUpload active={6}/>
                </div>

                <div className="uploadContainer">
                    <section
                        className="left"
                        style={{marginTop: this.state.marginTopValue}}
                    >
                        <div className="block_field">
                            <div className="form_control">
                                <label htmlFor="datepublication">{this.state.trad.datepublication}</label>
                                <input type="date" name="datepublication" value={this.state.datepublication} onChange={(e) => this.setState({datepublication:e.target.value})}/>
                            </div>
                        </div>
                        <div className="spacer"></div>
                        <div className="block_field">
                            <div className="form_control">
                                <label htmlFor="datenft">{this.state.trad.datenft}</label>
                                <input type="date" name="datenft" value={this.state.datenft} onChange={(e) => this.setState({datenft:e.target.value})}/>
                            </div>
                        </div>
                        <div className="block_field_checkbox">
                            <div className="form_control">
                                <input checked={this.state.politique} onClick={(e) => this.setState({politique:e.target.checked})} type="checkbox"/><span>{this.state.trad.validationPolitique}</span>
                            </div>
                        </div>
                    </section>

                    <section className="right">
                        <div className="upload_VignetteSection">
                            <div className="upload_VignetteText">
                                {
                                    this.state.video &&
                                    <div className="vignette">
                                    <VideoUpload
                                            indexVideo={0}
                                            upload={false}
                                            id={this.state.video.id}
                                            video={import.meta.env.VITE_BACKEND_URL_IMG + this.state.video.path_nocompres}
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="upload_VignetteText_video">
                            {
                                this.state.video &&
                                <>
                                    <p className="light_video">{this.state.video.title}</p>
                                    <p className="bold_video">{this.state.video.utilisateur.nom_artiste}</p>
                                </>
                            }
                        </div>
                    </section>
                </div>

                <p className="down_title">{this.state.trad.uploadTitle1}</p>
                <div className="navButtons">
                    <NavigateBackButton/>

                    <div className="form_submit_container">
                        {this.state.sendLauch ? (
                            <div className="lds-ring">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        ) : (
                            <div className="form_submit">
                                <img
                                    src={this.state.lang === "fr" ? continuerButton : changeValidButtonen}
                                    alt="continuer"
                                    className="continuer"
                                    onClick={this.handleUploadContinue}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}
