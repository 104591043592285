import React from "react";
import en from '../../../locales/en/translation.json';
import fr from '../../../locales/fr/translation.json';
import {Link} from "react-router-dom";

export default class MenuUpload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active:props.active,
            lang:'en',
            trad:en,
        }
    }
    componentDidMount() {
        if (JSON.parse(localStorage.getItem("user"))) {
            this.setState({
                user: JSON.parse(localStorage.getItem("user")),
            }, () => {
                let lang = this.state.user.user.language;
                if(lang === "fr"){
                    this.setState({lang:"fr",trad:fr});
                }
            });
        }
    }
    render(){
        return(
            <nav className="menuUpload">
                <ul>
                    <li className={`${this.state.active === 1 ? "link active" : "link"}`} ><Link to="/gestion-de-ma-page/upload-video">{this.state.trad.titleMenu}</Link></li>
                    <li className={`${this.state.active === 2 ? "link active" : "link"}`}><Link to="/gestion-de-ma-page/upload-video-part2">{this.state.trad.titleMenu1}</Link></li>
                    <li className={`${this.state.active === 3 ? "link active" : "link"}`}><Link to="/gestion-de-ma-page/upload-video-part3">{this.state.trad.titleMenu2}</Link></li>
                    <li className={`${this.state.active === 4 ? "link active" : "link"}`}><Link to="/gestion-de-ma-page/upload-video-part4">{this.state.trad.titleMenu3}</Link></li>
                    <li className={`${this.state.active === 5 ? "link active" : "link"}`}><Link to="/gestion-de-ma-page/upload-video-part5">NFT</Link></li>
                    <li className={`${this.state.active === 6 ? "link active" : "link"}`}><Link to="/gestion-de-ma-page/upload-video-part6">{this.state.trad.PUBLICATION}</Link></li>
                </ul>
            </nav>
        )
    }
}
