import React, {Component} from 'react';
import axios from "axios";


import people from "../../../../assets/logos/people.svg";
import rejoindre from '../../../../assets/logos/rejoindre.svg';
import inviter from '../../../../assets/logos/inviter.svg';
import inviteren from '../../../../assets/logos/en/inviteren.svg';
import dot from '../../../../assets/logos/dot_horizontal.svg';
import play_compte from "../../../../assets/logos/play_compte.png";
import fil from "../../../../assets/logos/noun-talk-2902537.svg";
import reposts from "../../../../assets/logos/reposts.svg";
import playlist from "../../../../assets/logos/playlist.svg";
import instant from "../../../../assets/logos/instant.svg";
import video from "../../../../assets/logos/video.svg";
import Filactu from "./Filactu.jsx";
import Invite from "../../modules/Invite.jsx";
import PlaylistPlace from "./PlaylistPlace.jsx";
import Videos from "./Videos.jsx";
import Reposts from "./reposts.jsx";
import AddPlaces from "../../modules/AddPlaces.jsx";
import FollowerList from "../../modules/FollowerList.jsx";
import en from '../../../../locales/en/translation.json';
import fr from '../../../../locales/fr/translation.json';
import SmallModal from "../../modules/ModalMini.jsx";


export default class Oneplace extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            height: screen.height + 2,
            id: "",
            place: null,
            openMenu: false,
            type: "fil",
            //type: "video",
            invite: false,
            openModifier: false,
            openDelete: false,
            follows: false,
            lang: 'en',
            trad: en,
        }
    }

    componentDidMount() {
        document.title = "Places | Wonderplace";
        document.querySelector("body").classList.add("oneplace");

        let user = localStorage.getItem('user');
        let id = location.pathname.split('/');
        id = id[2];
        this.setState({user: JSON.parse(localStorage.getItem('user')), id: parseInt(id)}, () => {
            const url = import.meta.env.VITE_BACKEND_URL + "/places/place/" + this.state.id;
            axios.get(url).then(res => {
                document.querySelector(".img_cover").style.backgroundImage = `linear-gradient(rgba(255, 255, 255, 0.2), rgb(0, 0, 0)),url(${import.meta.env.VITE_BACKEND_URL_IMG}${res.data.image_place})`;
                this.setState({place: res.data});
            }).catch(err => {
                console.log(err);
            })
            this.setState({
                user: JSON.parse(localStorage.getItem("user")),
            }, () => {
                let lang = this.state.user.user.language;
                if (lang === "fr") {
                    this.setState({lang: "fr", trad: fr});
                }
            });
        });
    }

    handleOpenMenu = () => {
        this.setState({openMenu: !this.state.openMenu});
    }
    handleCloseMenu = () => {
        this.setState({openMenu: false, invite: false, follows: false});

    }
    handleOpenInvite = () => {
        this.setState({invite: true})
    }
    handleAddInvite = () => {

    }
    handleOpenBoard = (value) => {
        this.setState({type: value});
    }
    handleModifierPlace = () => {
        this.setState({openModifier: true});
    }
    handleCloseModal = () => {
        this.setState({openModifier: false, openDelete: false});
    }
    callbackAddPlaces = (place) => {
        this.setState({place: place, openModifier: false}, () => {
            document.querySelector(".img_cover").style.backgroundImage = `linear-gradient(rgba(255, 255, 255, 0.2), rgb(0, 0, 0)),url(${import.meta.env.VITE_BACKEND_URL_IMG}${this.state.place.image_place})`;
        });
    }
    handleDeletePlace = () => {
        this.setState({openDelete: true});
    }
    handleDeleteSurPlace = () => {
        let url = import.meta.env.VITE_BACKEND_URL + "/places/delete/";
        axios.post(url, {
            id: this.state.id,
        }, {
            headers: {
                Authorization: "Bearer " + this.state.user.token,
            },
        }).then(res => {
            if(res.data){
                window.location.href = "/mes-places";
            }
        }).catch(err => {
            console.log(err);
        })
    }


    handleReportBlocked = (type) => {
        let url = "";
        axios.post(url, {
            type: type,
            id: this.state.id,
        }, {
            headers: {
                Authorization: "Bearer " + this.state.user.token,
            },
        }).then(res => {

        }).catch(err => {
            console.log(err);
        })
    }
    handleOpenFollow = () => {
        this.setState({follows: true});
    }


    render() {
        return (
            <>
                {
                    this.state.place !== null &&
                    <>
                        {
                            this.state.openModifier &&
                            <AddPlaces handleCloseModal={this.handleCloseModal}
                                       callbackAddPlaces={this.callbackAddPlaces}
                                       user={this.state.user} currentPlace={this.state.place} single={true}/>
                        }

                        <div className="placeHeader">
                            <div className="left">
                                <div className="play_block">
                                    <img src={play_compte} alt=""/>
                                    <p>{this.state.lang.pDescriptionPlaces11}</p>
                                </div>
                            </div>
                            <div className="center">
                                <h1><img src={people} alt=""/> {this.state.place.name}</h1>
                                {
                                    this.state.place.city && this.state.place.country &&
                                    <p>{this.state.place.city}, {this.state.place.country}</p>
                                }
                                <div className="social">
                                    <div className="block_social" style={{cursor: "pointer"}}
                                         onClick={this.handleOpenFollow}>
                                        <p className="number">{this.state.place.follow}</p>
                                        <p className="label">{this.state.trad.creatorFollowers}</p>
                                    </div>
                                    <div className="block_social" style={{cursor: "pointer"}}
                                         onClick={() => this.handleOpenBoard('reposts')}>
                                        <p className="number">{this.state.place.repost}</p>
                                        <p className="label">{this.state.trad.creatorRepost}</p>
                                    </div>
                                    <div className="block_social" style={{cursor: "pointer"}}
                                         onClick={() => this.handleOpenBoard('instant')}>
                                        <p className="number">0</p>
                                        <p className="label">{this.state.trad.creatorMoments}</p>
                                    </div>
                                </div>
                                <div className="description">
                                    <h3>{this.state.trad.headerNavSub7}</h3>
                                    <p>{this.state.place.description}</p>
                                    {/*
                                    <div className="amis_follow">
                                        <p>Trois de tes amis suivent {this.state.place.name}</p>
                                    </div>
                                    */}
                                </div>
                            </div>
                            <div className={`right ${this.state.user !== null ? '' : 'large'}`}>
                                <div className="block_menu_block">
                                    {
                                        this.state.user == null &&
                                        <img className="rejoindre"
                                             src={this.state.lang === "fr" ? rejoindre : rejoindreen} alt=""/>
                                    }
                                    <img onClick={this.handleOpenInvite} className="inviter"
                                         src={this.state.lang === "fr" ? inviter : inviteren} alt=""/>
                                    <div className="dropdown_menu" onMouseLeave={this.handleCloseMenu}>
                                        <img onClick={this.handleOpenMenu} src={dot} alt=""/>
                                        {
                                            this.state.openMenu &&
                                            <ul className="menu_drop">
                                                {
                                                    this.state.place.utilisateur_id !== this.state.user.user.id &&
                                                    <>
                                                        <li onClick={() => this.handleReportBlocked('report')}>{this.state.trad.pDescriptionPlaces13}</li>
                                                        <li onClick={() => this.handleReportBlocked('blocked')}>{this.state.trad.pDescriptionPlaces14}</li>
                                                    </>
                                                }
                                                {
                                                    this.state.place.utilisateur_id === this.state.user.user.id &&
                                                    <>
                                                        <li onClick={this.handleModifierPlace}>{this.state.trad.pDescriptionPlaces15}</li>
                                                        <li onClick={this.handleDeletePlace}>{this.state.trad.pDescriptionPlaces24}</li>
                                                    </>
                                                }
                                            </ul>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="block_content_page">
                            <nav>
                                <ul>
                                    <li className={this.state.type === "fil" ? "active" : "no_active"}
                                        onClick={() => this.handleOpenBoard('fil')}><img src={fil} alt=""/>
                                        <p>{this.state.trad.pDescriptionPlaces16}</p></li>
                                    <li className={this.state.type === "playlist" ? "active" : "no_active"}
                                        onClick={() => this.handleOpenBoard('playlist')}><img src={playlist} alt=""/>
                                        <p>{this.state.trad.creatorText3}</p></li>
                                    <li className={this.state.type === "video" ? "active" : "no_active"}
                                        onClick={() => this.handleOpenBoard('video')}><img src={video} alt=""/>
                                        <p>{this.state.trad.creatorText2}</p></li>
                                    <li className={this.state.type === "reposts" ? "active" : "no_active"}
                                        onClick={() => this.handleOpenBoard('reposts')}><img src={reposts} alt=""/>
                                        <p>{this.state.trad.creatorRepost}</p></li>
                                    <li className={this.state.type === "instant" ? "active" : "no_active"}
                                        onClick={() => this.handleOpenBoard('instant')}><img src={instant} alt=""/>
                                        <p>{this.state.trad.creatorMoments}</p></li>
                                </ul>
                            </nav>
                        </div>
                        <div className={`board ${this.state.type}`}>
                            {
                                this.state.type == "fil" &&
                                <Filactu user={this.state.user} fondateur={this.state.place.utilisateur_id}
                                         placeId={this.state.place.id}/>
                            }
                            {
                                this.state.type == "playlist" &&
                                <PlaylistPlace user={this.state.user} fondateur={this.state.place.utilisateur_id}
                                               placeId={this.state.place.id} place={this.state.place}/>
                            }
                            {
                                this.state.type == "video" &&
                                <Videos user={this.state.user} fondateur={this.state.place.utilisateur_id}
                                        placeId={this.state.place.id}/>
                            }
                            {
                                this.state.type == "reposts" &&
                                <Reposts user={this.state.user} fondateur={this.state.place.utilisateur_id}
                                         placeId={this.state.place.id}/>
                            }
                            {
                                this.state.type == "instant" &&
                                <p>{this.state.trad.creatorText5}</p>
                            }
                        </div>
                        {
                            this.state.invite &&
                            <Invite user={this.state.user} handleCloseMenu={this.handleCloseMenu}
                                    placeId={this.state.place.id}/>
                        }
                        {
                            this.state.follows &&
                            <FollowerList user={this.state.user} handleCloseMenu={this.handleCloseMenu}
                                          placeId={this.state.place.id}/>
                        }
                        {
                            this.state.openDelete &&
                            <SmallModal title={this.state.trad.pDescriptionPlaces24}
                                        label={this.state.trad.pDescriptionPlaces25}
                                        handleCloseModal={this.handleCloseModal}
                                        callbackSend={this.handleDeleteSurPlace}/>
                        }
                    </>
                }
            </>
        )
    }
}