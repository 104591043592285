import React, {Component} from "react";
import axios from "axios";

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import NavigateBackButton from "../../modules/NavigateBackButton.jsx";
import continuerButton from "../../../../assets/logos/continuerButton.svg";
import changeValidButtonen from "../../../../assets/logos/en/continuerButtonen.svg";

import {bannerHeight} from "../../modules/Helper.jsx";
import VideoUpload from "../../modules/VideoUpload.jsx";

import arrow from "../../../../assets/logos/arrow.svg";
import add from "../../../../assets/logos/ajouter_btn.svg";
import send from "../../../../assets/logos/send_email.svg";
import MenuUpload from "../../modules/MenuUpload.jsx";

import en from '../../../../locales/en/translation.json';
import fr from '../../../../locales/fr/translation.json';

export default class UploadVideo_part4 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            marginTopValue: "18rem", // Valeur initiale de margin-top de la div "left" pour que l'element image soit respnsive
            sendLauch: false,
            user: null,
            identifiants: null,

            openAuteur: false,
            openCompo: false,
            openRea: false,
            openMus: false,
            openVid: false,
            openLabel: false,
            openAdd: false,


            royalties: false,

            customTab: [],
            tab: {
                auteur: {
                    name: "",
                    identifiant: "",
                    email: "",
                },
                compositeur: {
                    name: "",
                    identifiant: "",
                    email: "",
                },
                realisation: {
                    name: "",
                    identifiant: "",
                    email: "",
                },
                musicale: {
                    name: "",
                    identifiant: "",
                    email: "",
                },
                video: {
                    name: "",
                    identifiant: "",
                    email: "",
                },
                label: {
                    name: "",
                    identifiant: "",
                    email: "",
                }
            },
            customType: "",
            customNom: "",
            customId: "",
            customEmail: "",
            flashMessage: "",
            typeFlash: "",

            lang:'en',
            trad:en,
        };
    }

    componentDidMount = () =>  {
        if (JSON.parse(localStorage.getItem("user"))) {
            this.setState({
                user: JSON.parse(localStorage.getItem("user")),
            }, () => {
                let lang = this.state.user.user.language;
                if(lang === "fr"){
                    this.setState({lang:"fr",trad:fr});
                }
            });
        }

        document.title = "Upload Video - DROITS D’AUTEUR ET DROITS VOISINS | Wonderplace";
        this.handleResize();
        window.addEventListener("resize", this.handleResize);
        bannerHeight();
        if (localStorage.getItem('storageType')) {
            let user = localStorage.getItem("user");
            if (user !== null) {
                this.setState({
                    user: JSON.parse(user),
                }, () => {
                    let video = localStorage.getItem('currentVideo');
                    video = JSON.parse(video);
                    let urlVideo = import.meta.env.VITE_BACKEND_URL + "/creator/getVideo";
                    axios.post(urlVideo, {
                        id: video.id,
                    }, {
                        headers: {
                            Authorization: "Bearer " + this.state.user.token,
                        },
                    }).then(res => {
                        console.log(res.data);
                        this.setState({
                            video: res.data,
                            royalties: res.data.infos.royalitise == 1 ? true : false,
                        }, () => {
                            let urlGetId = import.meta.env.VITE_BACKEND_URL + "/creator/identifiant";
                            axios.get(urlGetId, {
                                headers: {
                                    Authorization: "Bearer " + this.state.user.token,
                                },
                            }).then(res => {
                                this.setState({identifiants: res.data},() => {
                                    let {video, tab, customTab} = this.state;
                                    video.droits.map(value => {
                                        switch (value.type) {
                                            case "auteur":
                                                tab.auteur.name = value.name;
                                                tab.auteur.identifiant = value.identifiant;
                                                tab.auteur.email = value.email;
                                                break;
                                            case "compositeur":
                                                tab.compositeur.name = value.name;
                                                tab.compositeur.identifiant = value.identifiant;
                                                tab.compositeur.email = value.email;
                                                break;
                                            case "realisation":
                                                tab.realisation.name = value.name;
                                                tab.realisation.identifiant = value.identifiant;
                                                tab.realisation.email = value.email;
                                                break;
                                            case "musicale":
                                                tab.musicale.name = value.name;
                                                tab.musicale.identifiant = value.identifiant;
                                                tab.musicale.email = value.email;
                                                break;
                                            case "video":
                                                tab.video.name = value.name;
                                                tab.video.identifiant = value.identifiant;
                                                tab.video.email = value.email;
                                                break;
                                            case "label":
                                                tab.label.name = value.name;
                                                tab.label.identifiant = value.identifiant;
                                                tab.label.email = value.email;
                                                break;
                                            default:
                                                let newCustom = {};
                                                let test = customTab.filter(ct => ct.type == value.type);
                                                if(!test[0]){
                                                    newCustom.type = value.type;
                                                    newCustom.name = value.name;
                                                    newCustom.identifiant = value.identifiant;
                                                    newCustom.email = value.email;
                                                    customTab.push(newCustom);
                                                }
                                                break;
                                        }
                                    })
                                    this.setState({tab,customTab});
                                });
                            }).catch(err => {
                                console.log(err);
                            })
                        })
                    }).catch(err => {
                        console.log(err);
                    })
                });
            } else {
                location.assign("/");
            }
        }
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }
// Adapte "MarginTop" de la div "left"  afin de descendre en mobile
    handleResize = () => {
        const resolution = window.innerWidth;
        // Vérifier si la résolution est inférieure ou égale à 1280 pixels
        if (resolution < 1280) {
            // Calculer la nouvelle valeur "top" en fonction de la résolution
            const newMarginTopValue = 23 + resolution / 100; // Par exemple
            // Mettre à jour la valeur "MarginTop" dans le state
            this.setState({
                marginTopValue: newMarginTopValue + "rem",
            });
        } else {
            this.setState({
                marginTopValue: 0 + "rem",
            });
        }
    };
    handleAddValue = (value, type, field) => {
        let {tab} = this.state;
        tab[`${type}`][`${field}`] = value;
        this.setState({tab});
    }
    handleOpen = (type) => {
        this.setState({
            ...this.state,
            [type]: !this.state[`${type}`],
            flashMessage: "",
            typeFlash: "",
        });
    }
    handleCustomAdd = () => {
        let {customTab, customType, customNom, customId, customEmail,} = this.state;
        let newCustom = {};
        newCustom.type = customType;
        newCustom.name = customNom;
        newCustom.identifiant = customId;
        newCustom.email = customEmail;
        customTab.push(newCustom);
        this.setState({
            openAdd: false,
            customTab,
            customType: "",
            customEmail: "",
            customId: "",
            customNom: "",
        },() => {
            const url = import.meta.env.VITE_BACKEND_URL + "/upload/sendMailDroit";
            this.setState({
                flashMessage: "",
                typeFlash: "",
            });
            axios.post(url, {
                id:this.state.video.id,
                email:newCustom.email
            }, {
                headers: {
                    Authorization: "Bearer " + this.state.user.token,
                },
            }).then(res =>{
                this.setState({
                    flashMessage: this.state.trad.messageUploadVideo,
                    typeFlash: "success",
                });
            }).catch(err => {
                console.log(err);
            })
        })
    }
    handleAddCustomValue = (value, field, index) => {
        let {customTab} = this.state;
        customTab[index][`${field}`] = value;
        this.setState({customTab});
    }
    handleUploadContinue = (e) => {
        e.preventDefault();
        this.setState({
            flashMessage: "",
            typeFlash: "",
        });
        ///ma-page/upload-video-part5
        let {tab, customTab,royalties} = this.state;
        const url = import.meta.env.VITE_BACKEND_URL + "/upload/savePart4";
        axios.post(url, {
            tab,
            customTab,
            video_id: this.state.video.id,
            royalties,
        }, {
            headers: {
                Authorization: "Bearer " + this.state.user.token,
            },
        }).then(res => {
            if(localStorage.getItem('editvideo')){
                //localStorage.removeItem('editvideo');
                //localStorage.removeItem('currentVideo');
                //localStorage.setItem('message',"success:Votre vidéo a bien été modifié.");
                //location.assign("/gestion-de-ma-page");
                location.assign("/gestion-de-ma-page/upload-video-part5");
            }
        }).catch(err => {
            console.log(err);
        })

    };
    handleSendEmail = (type) => {
        const url = import.meta.env.VITE_BACKEND_URL + "/upload/sendMailDroit";
        this.setState({
            flashMessage: "",
            typeFlash: "",
        });
        axios.post(url, {
            id:this.state.video.id,
            email:this.state.tab[`${type}`].email
        }, {
            headers: {
                Authorization: "Bearer " + this.state.user.token,
            },
        }).then(res =>{
            this.setState({
                flashMessage: this.state.trad.messageUploadVideo,
                typeFlash: "success",
            });
        }).catch(err => {
            console.log(err);
        })
    }
    render() {
        return (


            <div className="uploadVideoGlobal_Part5">
                {
                    this.state.flashMessage !== "" &&
                    <div className={`message ${this.state.typeFlash}`}>
                        <p>{this.state.flashMessage}</p>
                    </div>
                }
                <div className="uploadHeader">
                    <h1>{this.state.trad.uploadHeader}</h1>
                    <MenuUpload active={4} />
                </div>

                <div className="uploadContainer">
                    <section
                        className="left"
                        style={{marginTop: this.state.marginTopValue}}
                    >
                        <div className="block_field">
                            <h4
                                onClick={() => this.handleOpen('openAuteur')}>
                                {this.state.trad.openAutor}
                                <img src={arrow} alt=""
                                     className={`arrow ${this.state.openAuteur ? "open" : "close"}`}/></h4>
                            <div className={`block ${this.state.openAuteur ? "open" : "close"}`}>
                                <div className="form_control">
                                    <label htmlFor="name">{this.state.trad.labelLastName}</label>
                                    <input type="text" value={this.state.tab.auteur.name}
                                           onChange={(e) => this.handleAddValue(e.target.value, "auteur", 'name')}/>
                                </div>
                                <div className="form_control">
                                    <label htmlFor="name">{this.state.trad.labelIdentifiant}</label>
                                    {
                                        this.state.identifiants !== null &&
                                        <Autocomplete
                                            id="combo-box-demo"
                                            options={this.state.identifiants}
                                            sx={{width: "100%"}}
                                            onChange={(e, value) => this.handleAddValue(value, "auteur", 'identifiant')}
                                            renderInput={(params) => <TextField {...params}  />}
                                        />
                                    }
                                </div>
                                <p>{this.state.trad.pIdentifiant}</p>
                                <div className="form_control email">
                                    <label htmlFor="name">{this.state.trad.labelMail}</label>
                                    <div className="btn_send_email">
                                        <input type="text"
                                               onChange={(e) => this.handleAddValue(e.target.value, "auteur", 'email')}/>
                                        <img onClick={() => this.handleSendEmail("auteur")} className="btn_send" src={send} alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="block_field">
                            <h4 onClick={() => this.handleOpen('openCompo')}>Composition <img src={arrow} alt=""
                                                                                              className={`arrow ${this.state.openCompo ? "open" : "close"}`}/>
                            </h4>
                            <div className={`block ${this.state.openCompo ? "open" : "close"}`}>
                                <div className="form_control">
                                    <label htmlFor="name">{this.state.trad.labelLastName}</label>
                                    <input type="text"
                                           onChange={(e) => this.handleAddValue(e.target.value, "compositeur", 'name')}/>
                                </div>
                                <div className="form_control">
                                    <label htmlFor="name">{this.state.trad.labelIdentifiant}</label>
                                    {
                                        this.state.identifiants !== null &&
                                        <Autocomplete
                                            id="combo-box-demo"
                                            options={this.state.identifiants}
                                            sx={{width: "100%"}}
                                            onChange={(e, value) => this.handleAddValue(value, "compositeur", 'identifiant')}
                                            renderInput={(params) => <TextField {...params}  />}
                                        />
                                    }
                                </div>
                                <p>{this.state.trad.pIdentifiant}</p>
                                <div className="form_control email">
                                    <label htmlFor="name">{this.state.trad.labelMail}</label>
                                    <div className="btn_send_email">
                                    <input type="text"
                                           onChange={(e) => this.handleAddValue(e.target.value, "compositeur", 'email')}/>
                                        <img onClick={() => this.handleSendEmail("compositeur")} className="btn_send" src={send} alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="block_field">
                            <h4 onClick={() => this.handleOpen('openRea')}>{this.state.trad.openRea} <img src={arrow} alt=""
                                                                                            className={`arrow ${this.state.openRea ? "open" : "close"}`}/>
                            </h4>
                            <div className={`block ${this.state.openRea ? "open" : "close"}`}>
                                <div className="form_control">
                                    <label htmlFor="name">{this.state.trad.labelLastName}</label>
                                    <input type="text"
                                           onChange={(e) => this.handleAddValue(e.target.value, "realisation", 'name')}/>
                                </div>
                                <div className="form_control">
                                    <label htmlFor="name">{this.state.trad.labelIdentifiant}</label>
                                    {
                                        this.state.identifiants !== null &&
                                        <Autocomplete
                                            id="combo-box-demo"
                                            options={this.state.identifiants}
                                            sx={{width: "100%"}}
                                            onChange={(e, value) => this.handleAddValue(value, "realisation", 'identifiant')}
                                            renderInput={(params) => <TextField {...params}  />}
                                        />
                                    }
                                </div>
                                <p>{this.state.trad.pIdentifiant}</p>
                                <div className="form_control email">
                                    <label htmlFor="name">{this.state.trad.labelMail}</label>
                                    <div className="btn_send_email">
                                    <input type="text"
                                           onChange={(e) => this.handleAddValue(e.target.value, "realisation", 'email')}/>
                                        <img onClick={() => this.handleSendEmail("realisation")} className="btn_send" src={send} alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="block_field">
                            <h4 onClick={() => this.handleOpen('openMus')}>{this.state.trad.openMus} <img src={arrow} alt=""
                                                                                                    className={`arrow ${this.state.openMus ? "open" : "close"}`}/>
                            </h4>
                            <div className={`block ${this.state.openMus ? "open" : "close"}`}>
                                <div className="form_control">
                                    <label htmlFor="name">{this.state.trad.labelLastName}</label>
                                    <input type="text"
                                           onChange={(e) => this.handleAddValue(e.target.value, "musicale", 'name')}/>
                                </div>
                                <div className="form_control">
                                    <label htmlFor="name">{this.state.trad.labelIdentifiant}</label>
                                    {
                                        this.state.identifiants !== null &&
                                        <Autocomplete
                                            options={this.state.identifiants}
                                            sx={{width: "100%"}}
                                            onChange={(e, value) => this.handleAddValue(value, "musicale", 'identifiant')}
                                            renderInput={(params) => <TextField {...params}  />}
                                        />
                                    }
                                </div>
                                <p>{this.state.trad.pIdentifiant}</p>
                                <div className="form_control email">
                                    <label htmlFor="name">{this.state.trad.labelMail}</label>
                                    <div className="btn_send_email">
                                    <input type="text"
                                           onChange={(e) => this.handleAddValue(e.target.value, "musicale", 'email')}/>
                                        <img onClick={() => this.handleSendEmail("musicale")} className="btn_send" src={send} alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="block_field">
                            <h4 onClick={() => this.handleOpen('openVid')}>{this.state.trad.openVid} <img src={arrow} alt=""
                                                                                                 className={`arrow ${this.state.openVid ? "open" : "close"}`}/>
                            </h4>
                            <div className={`block ${this.state.openVid ? "open" : "close"}`}>
                                <div className="form_control">
                                    <label htmlFor="name">{this.state.trad.labelLastName}</label>
                                    <input type="text"
                                           onChange={(e) => this.handleAddValue(e.target.value, "video", 'name')}/>
                                </div>
                                <div className="form_control">
                                    <label htmlFor="name">{this.state.trad.labelIdentifiant}</label>
                                    {
                                        this.state.identifiants !== null &&
                                        <Autocomplete
                                            options={this.state.identifiants}
                                            sx={{width: "100%"}}
                                            onChange={(e, value) => this.handleAddValue(value, "video", 'identifiant')}
                                            renderInput={(params) => <TextField {...params}  />}
                                        />
                                    }
                                </div>
                                <p>{this.state.trad.pIdentifiant}</p>
                                <div className="form_control email">
                                    <label htmlFor="name">{this.state.trad.labelMail}</label>
                                    <div className="btn_send_email">
                                    <input type="text"
                                           onChange={(e) => this.handleAddValue(e.target.value, "video", 'email')}/>
                                        <img onClick={() => this.handleSendEmail("video")} className="btn_send" src={send} alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="block_field">
                            <h4 onClick={() => this.handleOpen('openLabel')}>{this.state.trad.openLabel} <img src={arrow} alt=""
                                                                                        className={`arrow ${this.state.openLabel ? "open" : "close"}`}/>
                            </h4>
                            <div className={`block ${this.state.openLabel ? "open" : "close"}`}>
                                <div className="form_control">
                                    <label htmlFor="name">{this.state.trad.labelLastName}</label>
                                    <input type="text"
                                           onChange={(e) => this.handleAddValue(e.target.value, "label", 'name')}/>
                                </div>
                                <div className="form_control">
                                    <label htmlFor="name">{this.state.trad.labelIdentifiant}</label>
                                    {
                                        this.state.identifiants !== null &&
                                        <Autocomplete

                                            id="combo-box-demo"
                                            options={this.state.identifiants}
                                            sx={{width: "100%"}}
                                            onChange={(e, value) => this.handleAddValue(value, "label", 'identifiant')}
                                            renderInput={(params) => <TextField {...params}  />}
                                        />
                                    }
                                </div>
                                <p>{this.state.trad.pIdentifiant}</p>
                                <div className="form_control email">
                                    <label htmlFor="name">{this.state.trad.labelMail}</label>
                                    <div className="btn_send_email">
                                    <input type="text"
                                           onChange={(e) => this.handleAddValue(e.target.value, "label", 'email')}/>
                                        <img onClick={() => this.handleSendEmail("label")} className="btn_send" src={send} alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            this.state.customTab[0] &&
                            this.state.customTab.map((value, index) => (
                                <div className="block_field" key={index}>
                                    <h4 onClick={() => this.handleOpen(`custom_${index}`)}>{value.type} <img src={arrow}
                                                                                                             alt=""
                                                                                                             className={`arrow ${this.state[`custom_${index}`] ? "open" : "close"}`}/>
                                    </h4>

                                    <div className={`block ${this.state[`custom_${index}`] ? "open" : "close"}`}>
                                        <div className="form_control">
                                            <label htmlFor="name">{this.state.trad.labelType}</label>
                                            <input type="text" value={value.type}
                                                   onChange={(e) => this.handleAddCustomValue(e.target.value, 'type', index)}/>
                                        </div>
                                        <div className="form_control">
                                            <label htmlFor="name">{this.state.trad.labelLastName}</label>
                                            <input type="text" value={value.name}
                                                   onChange={(e) => this.handleAddCustomValue(e.target.value, 'name', index)}/>
                                        </div>
                                        <div className="form_control">
                                            <label htmlFor="name">{this.state.trad.labelIdentifiant}</label>
                                            {
                                                this.state.identifiants !== null &&
                                                <Autocomplete
                                                    options={this.state.identifiants}
                                                    sx={{width: "100%"}}
                                                    defaultValue={value.identifiant}
                                                    onChange={(e, value) => this.handleAddCustomValue(e.target.value, 'identifiant', index)}
                                                    renderInput={(params) => <TextField {...params}  />}
                                                />
                                            }
                                        </div>
                                        <p>{this.state.trad.pIdentifiant}</p>
                                        <div className="form_control email">
                                            <label htmlFor="name">{this.state.trad.labelMail}</label>
                                            <div className="btn_send_email">
                                            <input type="text" value={value.email}
                                                   onChange={(e) => this.handleAddCustomValue(e.target.value, 'email', index)}/>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                        <div className="block_field background">
                            <h4 onClick={() => this.handleOpen('openAdd')}>{this.state.trad.openAdd} <span>+</span></h4>
                            <div className={`block ${this.state.openAdd ? "open" : "close"}`}>
                                <div className="form_control">
                                    <label htmlFor="name">{this.state.trad.labelType}</label>
                                    <input type="text" value={this.state.customType}
                                           onChange={(e) => this.setState({customType: e.target.value})}/>
                                </div>
                                <div className="form_control">
                                    <label htmlFor="name">{this.state.trad.labelLastName}</label>
                                    <input type="text"
                                           onChange={(e) => this.setState({customNom: e.target.value})}/>
                                </div>
                                <div className="form_control">
                                    <label htmlFor="name">{this.state.trad.labelIdentifiant}</label>
                                    {
                                        this.state.identifiants !== null &&
                                        <Autocomplete
                                            options={this.state.identifiants}
                                            sx={{width: "100%"}}
                                            onChange={(e, value) => this.setState({customId: value})}
                                            renderInput={(params) => <TextField {...params}  />}
                                        />
                                    }
                                </div>
                                <p>{this.state.trad.pIdentifiant}</p>
                                <div className="form_control">
                                    <label htmlFor="name">{this.state.trad.labelMail}</label>
                                    <input type="text"
                                           onChange={(e) => this.setState({customEmail: e.target.value})}/>
                                </div>
                                <div className="form_control btn">
                                    <img onClick={() => this.handleCustomAdd()} src={add} alt=""/>
                                </div>
                            </div>
                        </div>

                    </section>

                    <section className="right">
                        <div className="upload_VignetteSection">
                            <div className="upload_VignetteText">
                                {
                                    this.state.video &&
                                    <div className="vignette">
                                        <VideoUpload
                                            indexVideo={0}
                                            upload={false}
                                            id={this.state.video.id}
                                            video={import.meta.env.VITE_BACKEND_URL_IMG + this.state.video.path_nocompres}
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="royalties">
                            <h2>{this.state.trad.titleRoyalties}</h2>
                            <p>
                                <input type="checkbox" checked={this.state.royalties} value={this.state.royalties}
                                       onChange={(e) => this.setState({royalties: e.target.checked})}/>{this.state.trad.pRoyalties}</p>
                        </div>
                    </section>
                </div>

                <div className="navButtons">
                    <NavigateBackButton/>

                    <div className="form_submit_container">
                        {this.state.sendLauch ? (
                            <div className="lds-ring">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        ) : (
                            <div className="form_submit">

                                <img
                                    src={this.state.lang === "fr" ? continuerButton : changeValidButtonen}
                                    alt="continuer"
                                    className="continuer"
                                    onClick={this.handleUploadContinue}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}
