import React, { useState, useContext,useEffect } from 'react';

// Créer le contexte
export const VideoContext = React.createContext();

// Créer un fournisseur de contexte
export function VideoProvider({ children , videosBought}) {
    const [currentTime, setCurrentTime] = useState(0);
    const [bought, setBought] = useState([]);
    useEffect(() => {
        setBought(videosBought);
    }, [videosBought]);

    return (
        <VideoContext.Provider value={{ currentTime, setCurrentTime, bought, setBought }}>
            {children}
        </VideoContext.Provider>
    );
}
