import React, {useEffect, useRef, useState} from "react";
import videojs from "video.js";

import VideoJS from "../modules/VideoJS";
import axios from "axios";
import videoLikeIcon from "../../../assets/logos/videoLikeIcon.svg";
import videoLikeFullIcon from "../../../assets/logos/videoLikeFullIcon.svg";
import videoUnlikeIcon from "../../../assets/logos/coeur_vide.svg";
import videoUnlikeIconPlein from "../../../assets/logos/coeur_plein.svg";
import videoCommentIcon from "../../../assets/logos/videoCommentIcon.svg";
import videoBookmarkIcon from "../../../assets/logos/videoBookmarkIcon.svg";
import videoBookmarkIconFull from "../../../assets/logos/videoBookmarkIconFull.svg";
import videoFollowIcon from "../../../assets/logos/videoFollowIcon.svg";
import note from "../../../assets/logos/blockInfo/noun-note-1269186.svg";
import camera from "../../../assets/logos/blockInfo/noun-camera-5450150.svg";
import more from "../../../assets/logos/blockInfo/noun-more-4025305.svg";

// genreIcons pour test en dur //
import genreSombreIcon from "../../../assets/samples/genreSombreIcon.svg";
import Connexion from "./Connexion";
import Commentaire from "./Commentaire";
import BookMarke from "./BookMarke.jsx";
import Follow from "./Follow.jsx";
import dot from "../../../assets/logos/dot_horizontal.svg";
//////////////////////////////////////////////////////////////////////////////////

const VideoPlayer = (props) => {
    const playerRef = useRef(null);
    const video = props.video;
    const [id, setId] = useState(props.id);
    const [indexVideo, setIndexVideo] = useState(props.indexVideo);
    const [currentTime, setCurrentTime] = useState(props.currentTime);
    const [duration, setDuration] = useState();
    const [user, setUser] = useState(props.user);
    const [openModal, setOpenModal] = useState(props.openModal);
    const [openModalCom, setOpenModalCom] = useState(props.openModalCom);
    const [openModalBook, setOpenModalBook] = useState(props.openModalBook);
    const [openModalFollow, setOpenModalFollow] = useState(props.openModalFollow);
    const [message, setMessage] = useState('');
    const [styleMessage, setStyleMessage] = useState('');
    const [trad, setTrad] = useState(props.trad);
    const [openInfo, setOpenInfo] = useState(false);
    const [currentVideoTime, setCurrentVideoTime] = useState(null);

    // Player Footer Variables //
    const artistPhoto = video.artistPhoto;
    const artistTitle = video.artistTitle;
    const title = video.title;
    const artistMoreDetails = video.artistMoreDetails;
    const worldArtiste = video.worldArtiste;
    const otherInfo = video.otherInfo;
    const droit = video.droit;
    const userId = video.userId;
    const isOpenBook = video.isOpenBook;
    const isOpenRepost = video.isOpenRepost;
    const isOpenLike = video.isOpenLike;
    const isOpenCom = video.isOpenCom;

    // Player Footer States //
    const [hasLiked, setHasLiked] = useState(video.hasLiked);
    const [isBooked, setIsBooked] = useState(video.isBooked);
    const [hasDisLiked, setHasDisLiked] = useState(video.hasDisLiked);
    const [isLiked, setIsLiked] = useState(video.isLiked);
    const [isCommented, setIsCommented] = useState(video.isCommented);
    const [isBoorkmarked, setIsBoorkmarked] = useState(video.isBoorkmarked);
    const [isFollowed, setIsFollowed] = useState(video.isFollowed);
    const [genreIcon, setGenreIcon] = useState([video.genreIcon]);

    const [videoJsOptions,setVideoJsOptions] = useState({
        autoplay: video.isPlay,
        controls: true,
        preload: "auto",
        responsive: true,
        fluid: true,
        muted: false,
        aspectRatio: "16:9",
        currentTime: currentTime,
        controlBar: {
            remainingTimeDisplay: false,
            disablePictureInPicture: true,
            timeDivider: true,
            children: [
                "playToggle",
                "volumePanel",
                "progressControl",
                "currentTimeDisplay",
                "timeDivider",
                "durationDisplay",
                //"fullscreenToggle",
                // "restartButton",  //// RestartButton is custom, the order is done in VideoJS.jsx with the index
                //  "nextVideoButton",  //// NextVideoButton is custom, the order is done in VideoJS.jsx with the index
            ],
        },
        sources: [props.video],
        poster: props.video.poster,
    });
    useEffect(() => {
        setVideoJsOptions((prevOptions) => {
            return { ...prevOptions, sources: [props.video] };
        });
    }, [props.video]);
// Effet pour gérer la lecture
    useEffect(() => {
        if (props.video.isPlay) {
            handlePlay();
        }
    }, [props.video.isPlay]);
// Effet pour gérer la pause
    useEffect(() => {
        if (!props.video.isPlay) {
            handlePause();
        }
    }, [props.video.isPlay]);
    const handlePlayerReady = (player) => {
        playerRef.current = player;
        player.on("loadedmetadata", () => {
            const duration = player.duration();
            setDuration(duration);
        });
        player.on("waiting", () => {
            //videojs.log("player is waiting "+indexVideo);
        });
        player.on("dispose", () => {
            //videojs.log("player will dispose "+indexVideo);
        });
    };
    const handlePlay = () => {
        if (playerRef.current) {
            playerRef.current.play().catch(function (erreur) {

            });
        }
    };
    const handlePause = () => {
        if (playerRef.current) {
            // videojs.log(playerRef.current);
            playerRef.current.pause();
        }
    };
    const handleCallback = (id) => {
        let newId = parseInt(id) + 1;
        setIndexVideo(newId);
        props.handleCallBackPlay(newId);
        // setRefresh(true);
    };
    // Player FooterIcons //
    const handleLike = () => {
        setCurrentVideoTime(playerRef.current.currentTime());
        playerRef.current.pause();
        setCurrentVideoTime(currentTime);
        if (user !== null) {
            let url = import.meta.env.VITE_BACKEND_URL + "/like/likevideo";
            axios.post(url, {
                id: video.id,
            }, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + user.token
                }
            }).then(res => {
                setIsLiked(res.data.like);
                setHasLiked(res.data.hasLiked)
            }).catch(err => {
                console.log(err);
            })
        } else {
            setMessage('Vous devez être connecter pour liker une vidéo');
            setStyleMessage('warning');
            setOpenModal(true);
        }
        playerRef.current.play();
    }
    const handleUnlike = () => {
        setCurrentVideoTime(playerRef.current.currentTime());
        playerRef.current.pause();
        setCurrentVideoTime(currentTime);
        if (user !== null) {
            let url = import.meta.env.VITE_BACKEND_URL + "/like/dislikevideo";
            axios.post(url, {
                id: video.id,
            }, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + user.token
                }
            }).then(res => {
                setIsLiked(res.data.like);
                setHasLiked(res.data.hasLiked)
                setHasDisLiked(res.data.hasDisLiked)
            }).catch(err => {
                console.log(err);
            })
        } else {
            setMessage('Vous devez être connecter pour disliker une vidéo');
            setStyleMessage('warning');
            setOpenModal(true);
        }
        playerRef.current.play();
    };
    const handleComment = () => {
        if (user !== null) {
            setOpenModalCom(true);
        } else {
            setMessage('Vous devez être connecter pour voir les commentaires');
            setStyleMessage('warning');
            setOpenModal(true);
        }
    };
    const handleBookmark = () => {
        if (user !== null) {
            setOpenModalBook(true);
        } else {
            setMessage('Vous devez être connecter pour enregistrer un Bookmarke.');
            setStyleMessage('warning');
            setOpenModal(true);
        }
    };
    const handleFollow = () => {
        if (user !== null) {
            setOpenModalFollow(true);
        } else {
            setMessage('Vous devez être connecter pour enregistrer un Bookmarke.');
            setStyleMessage('warning');
            setOpenModal(true);
        }
    };
    const handlePlus = () => {
        setGenreIcon((prevIcons) => [
            ...prevIcons,
            <img
                src={genreSombreIcon}
                alt="Video Genre Sombre Icon"
                className="genreIcon"
                key={`genreIcon${prevIcons.length}`}
            />,
        ]);
    };
    const handleCloseModal = () => {
        setOpenModal(false);
        setOpenModalCom(false);
        setOpenModalBook(false);
        setOpenModalFollow(false);
        setMessage("");
        setStyleMessage('');
        //props.callBackFreshPage();
    }
    const handleOpenMenuDot = (index) => {
        let menu = document.querySelector(`#menu_${index}`);
        if (menu.style.display === "none") {
            menu.style.display = "block";
        } else {
            menu.style.display = "none";
        }
    }
    const handleOpenMoreInfo = () => {
        setOpenInfo(!openInfo);
    }
    const truncateString = (str, len = 100) => {
        if (str.length <= len) {
            return str;
        }
        return str.slice(0, len) + "...";
    }

    return (
        <>
            {
                openModal &&
                <Connexion handleCloseModal={handleCloseModal} message={message} styleMessage={styleMessage}/>
            }
            {
                openModalCom &&
                <Commentaire handleCloseModal={handleCloseModal} idvideo={video.id} user={user}/>
            }
            {
                openModalBook &&
                <BookMarke handleCloseModal={handleCloseModal} idvideo={video.id} user={user}/>
            }
            {
                openModalFollow &&
                <Follow handleCloseModal={handleCloseModal} video={video} user={user}/>
            }
            <div className="videoPlayerGlobal small" id={id}>
                {
                    message !== "" &&
                    <div className={`message ${styleMessage}`}>
                        <p>{message}</p>
                    </div>
                }
                <div className="videoPlayerContainer">
                    <VideoJS
                        options={videoJsOptions}
                        handleCallback={handleCallback}
                        handleCallBackFullScreen={(props.handleCallBackFullScreen)}
                        indexVideo={indexVideo}
                        onReady={handlePlayerReady}
                        ref={playerRef}
                        indexID={indexVideo}
                        callbackIndexVideo={props.callbackIndexVideo}
                        duration={duration}
                        id={id}
                        token={video.token}
                        nft={video.nft}
                    />
                    {
                        openInfo &&
                        <div className="moreInfo">
                            <div className="block">
                                <div className="headerBlock">
                                    <img src={note} alt=""/>
                                    <h3>{trad.infos1_1}<br/>
                                        {trad.infos1_2}</h3>
                                </div>
                                <div className="cententBlock">
                                    <p dangerouslySetInnerHTML={{__html: worldArtiste}}></p>
                                </div>
                            </div>
                            <div className="block">
                                <div className="headerBlock">
                                    <img src={camera} alt=""/>
                                    <h3>{trad.infos2_1}<br/>
                                        {trad.infos2_2}</h3>
                                </div>
                                <div className="cententBlock">
                                    <p>
                                        {trad.openAutor} :
                                        {
                                            droit !== undefined &&
                                            droit.filter(value => value.type === "auteur")
                                                .map((value, index, self) =>
                                                    <span key={index}>
                                                      {value.name}{index < self.length - 1 ? ',' : ''}
                                                  </span>
                                                )
                                        }
                                    </p>
                                    <p>
                                        {trad.openCompo} :
                                        {
                                            droit !== undefined &&
                                            droit.filter(value => value.type === "compositeur")
                                                .map((value, index, self) =>
                                                    <span key={index}>
                                                      {value.name}{index < self.length - 1 ? ',' : ''}
                                                  </span>
                                                )
                                        }
                                    </p>
                                    <p>
                                        {trad.openRea} :
                                        {
                                            droit !== undefined &&
                                            droit.filter(value => value.type === "realisation")
                                                .map((value, index, self) =>
                                                    <span key={index}>
                                                      {value.name}{index < self.length - 1 ? ',' : ''}
                                                  </span>
                                                )
                                        }
                                    </p>
                                    <p>
                                        {trad.openMus} :
                                        {
                                            droit !== undefined &&
                                            droit.filter(value => value.type === "musicale")
                                                .map((value, index, self) =>
                                                    <span key={index}>
                                                      {value.name}{index < self.length - 1 ? ',' : ''}
                                                  </span>
                                                )
                                        }
                                    </p>
                                    <p>
                                        {trad.openVid} :
                                        {
                                            droit !== undefined &&
                                            droit.filter(value => value.type === "video")
                                                .map((value, index, self) =>
                                                    <span key={index}>
                                                      {value.name}{index < self.length - 1 ? ',' : ''}
                                                  </span>
                                                )
                                        }
                                    </p>
                                    <p>
                                        {trad.openLabel} :
                                        {
                                            droit !== undefined &&
                                            droit.filter(value => value.type === "label")
                                                .map((value, index, self) =>
                                                    <span key={index}>
                                                      {value.name}{index < self.length - 1 ? ',' : ''}
                                                  </span>
                                                )
                                        }
                                    </p>
                                </div>
                            </div>
                            <div className="block">
                                <div className="headerBlock">
                                    <img src={more} alt=""/>
                                    <h3>{trad.infos3_1}<br/>
                                        {trad.infos3_2}</h3>
                                </div>
                                <div className="cententBlock">
                                    <p dangerouslySetInnerHTML={{__html: otherInfo}}></p>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="videoFooter">
                        <div className="artistDetailsContainer">
                            <a href={`/page-de-profil/${userId}`}>
                                <img
                                    className="artistPhoto"
                                    src={artistPhoto}
                                    alt="artistPhoto"
                                ></img>
                            </a>
                            <div className="artistInfosContainer">
                                <div className="artistInfos">
                                    <h3 className="artistName">
                                        {title} &nbsp;— &nbsp; {artistTitle}
                                        <img style={{marginLeft: "10px", cursor: "pointer"}}
                                             onClick={() => handleOpenMenuDot(indexVideo)} src={dot} alt=""/>
                                    </h3>
                                    <div id={`menu_${indexVideo}`} className="menu_video">
                                        <ul>
                                            <li onClick={() => props.handleSendBug("bug", indexVideo)}>{trad.pDescriptionUtilisateur3}</li>
                                            <li onClick={() => props.handleSendBug("contenu", indexVideo)}>{trad.pDescriptionUtilisateur4}</li>
                                        </ul>
                                    </div>
                                </div>
                                <p className="artistDescription">{truncateString(worldArtiste, 50)}</p>
                                <p className="artistMoreDetails" onClick={handleOpenMoreInfo}>{artistMoreDetails}</p>
                            </div>
                        </div>


                        <div className="videoIconsContainer">
                            <div className="videoIcons">
                                <div className="video_icon">
                                    {
                                        !isOpenLike &&
                                        <label>{isLiked}</label>
                                    }
                                    <img
                                        src={hasLiked ? videoLikeFullIcon : videoLikeIcon}
                                        alt="Video Like Icon"
                                        className="videoLikeIcon"
                                        onClick={handleLike}
                                    />
                                </div>

                                <div className="video_icon">
                                    <img
                                        src={hasDisLiked ? videoUnlikeIconPlein : videoUnlikeIcon}
                                        alt="Video Unlike Icon"
                                        className="videoUnlikeIcon"
                                        onClick={handleUnlike}
                                    />
                                </div>
                                    <div className="video_icon">
                                {
                                    !isOpenCom &&
                                    <>
                                        <label>{isCommented}</label>
                                        <img
                                            src={videoCommentIcon}
                                            alt="Video Comment Icon"
                                            className="videoCommentIcon"
                                            onClick={handleComment}
                                        />
                                    </>
                                }
                                    </div>
                                    <div className="video_icon">
                                {
                                    !isOpenBook &&
                                    <>
                                        <label>{isBoorkmarked}</label>
                                        <img
                                            src={isBooked ? videoBookmarkIconFull : videoBookmarkIcon}
                                            alt="Video Bookmark Icon"
                                            className="videoBookmarkIcon"
                                            onClick={handleBookmark}
                                        />
                                    </>
                                }
                                    </div>

                                    <div className="video_icon">
                                {
                                    !isOpenRepost &&
                                    <>
                                        <label>{isFollowed}</label>
                                        <img
                                            src={videoFollowIcon}
                                            alt="Video Follow Icon"
                                            className="videoFollowIcon"
                                            onClick={handleFollow}
                                        />
                                    </>
                                }
                                    </div>

                            </div>
                            {/*
                            <div className="videoGenresContainer">
                                <div className="videoGenres">
                                    {genreIcon.map((icon, index) => (
                                        <li className="video_genre_icon" key={index}>
                                            {icon}
                                        </li>
                                    ))}

                                    <div className="video_genre_icon">
                                        <img
                                            src={videoPlusIcon}
                                            alt="Video Plus Icon"
                                            className="videoPlusIcon"
                                            onClick={handlePlus}
                                        />
                                    </div>
                                </div>
                            </div>
                            */}
                        </div>
                    </div>
                </div>
            </div>

        </>

    );
};

export default VideoPlayer;
