import React, {Component} from 'react';
import axios from "axios";
import vignette4 from "../../../../assets/images/vignette4.jpg";
import eye from "../../../../assets/logos/eye.svg";
import playlistDefault from "../../../../assets/images/5.png";
import cadena from "../../../../assets/logos/cadena.svg";


export default class ExplorerNew extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            creators: null,
            places: null,
            playlists: null,
            videos: null,
        }
    }

    componentDidMount() {
        const url = import.meta.env.VITE_BACKEND_URL + "/explorer/new/get";
        axios.get(url).then(res => {
            this.setState({
                creators: res.data.creators,
                places: res.data.places,
                playlists: res.data.playlists,
                videos: res.data.videos
            });
        }).catch(err => {
            console.log(err);
        })
    }


    render() {
        return (
            <div className="list">
                <div className="bestlike">
                    <h2>Nouveautés Vidéos ></h2>
                    <div className="block_map">
                        {
                            this.state.videos &&
                            this.state.videos.map((value, index) => (
                                <div className="block_video" key={index}>
                                    <div className="img">
                                        <a href="">
                                            <img
                                                src={value.miniature !== null ? import.meta.env.VITE_BACKEND_URL_IMG + value.miniature : vignette4}
                                                alt=""/>
                                            <p className="time">{value.time}</p>

                                        </a>
                                    </div>
                                    <div className="info_video">
                                        <p className="title">{value.title}</p>
                                        {
                                            value.utilisateur &&
                                            value.utilisateur.nom_artiste &&
                                            <p className="creator">{value.utilisateur.nom_artiste}</p>
                                        }
                                        <p className="view"><img src={eye} alt=""/> 200</p>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className="bestlike">
                    <h2>Nouveautés Creators ></h2>
                    <div className="block_map">
                        {
                            this.state.creators &&
                            this.state.creators.map((value, index) => (
                                <div key={index} className="block_creator">
                                    <a href={`/page-de-profil/${value.id}`}>
                                        <div className="img">
                                            <img
                                                src={import.meta.env.VITE_BACKEND_URL_IMG + value.personal.avatar}
                                                alt=""/>
                                        </div>
                                        <div className="titre">
                                            <p>{value.nom_artiste}</p>
                                        </div>
                                    </a>
                                </div>

                            ))
                        }
                    </div>
                </div>
                <div className="bestlike">
                    <h2>Nouveautés Playlists ></h2>
                    <div className="block_map">
                        {
                            this.state.playlists &&
                            this.state.playlists.map((value, index) => (
                                <div key={index} className="block_playlist">
                                    <div className="left">
                                        <a href={`/playlist/${value.id}`}>
                                            <img
                                                src={value.image !== null ? import.meta.env.VITE_BACKEND_URL_IMG + value.image : playlistDefault}
                                                alt=""/>
                                            {
                                                value.public === 1 &&
                                                <img className="cadena" src={cadena} alt=""/>
                                            }
                                        </a>
                                    </div>
                                    <div className="right">
                                        <p>{value.name}</p>
                                        <p>par {value.user.name}</p>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className="bestlike">
                    <h2>Nouveautés Places ></h2>
                    <div className="block_map">
                        {
                            this.state.places &&
                            this.state.places.map((value, index) => (
                                <div key={index} className="block_playlist">
                                    <div className="left">
                                        <a href={`/place/${value.id}`}>
                                            <img
                                                src={value !== null ? import.meta.env.VITE_BACKEND_URL_IMG + value.image_place : playlistDefault}
                                                alt=""/>
                                        </a>
                                    </div>
                                    <div className="right">
                                        <p>{value.name}</p>
                                        <p>#{value.city} {value.country}</p>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        )
    }
}
