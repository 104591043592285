import React, {useContext, useEffect, useState} from 'react';
import VideoDataContext from '../../VideoDataContext';
import axios from 'axios';
import Web3 from 'web3';
import CryptoJS from 'crypto-js';
import continu from '../../../assets/logos/continuerButton.svg';
import cross from '../../../assets/logos/crossIcon.svg';
import continuEn from '../../../assets/logos/en/continuerButtonen.svg';
import ondeSmall from "../../../assets/wallet/ondeSmall.svg";
import send from "../../../assets/wallet/send.svg";
import {parseInt} from "lodash";

import contractAbi from '../../../../build/contracts/NFTContract.json';
//const contractAbi = require('../../../../build/contracts/NFTFactory.json');
const CONTRACT_ABI = contractAbi.abi;
const CONTRACT_ADDRESS = import.meta.env.VITE_APP_NFT_CONTRACT_ADDRESS;


// Pour le réseau principal de Polygon (Mainnet)
//const web3 = new Web3('https://polygon-mainnet.infura.io/v3/145ed7b2b61e42729c90d6d31cc54c29');

// Pour le réseau de test de Polygon (Mumbai)
//const web3 = new Web3('https://polygon-mumbai.infura.io/v3/145ed7b2b61e42729c90d6d31cc54c29');

//import NFTFactoryABI from '../../../../build/contracts/NFTFactory.json';


//let key = import.meta.env.VITE_DECRYPTION_KEY;
//let cryptoAccounts = CryptoJS.AES.decrypt(res.data.test.test, key).toString();
// Créez une instance de votre smart contracts factory
//const nftFactoryContract = new web3.eth.Contract(NFTFactoryABI, import.meta.env.REACT_APP_NFT_CONTRACT_ADDRESS); // Remplacez par l'adresse de votre contrat déployé
//let priceInWei = web3.utils.toWei(price.toString(), 'matic');
// Appellez la fonction createNFTContract de votre smart contracts factory
//await nftFactoryContract.methods.mint(props.video.token,startTime,startTime+5,cryptoAccounts,account,price).send({from: account,value: priceInWei});

const ShopPage = (props) => {
    const {videoData} = useContext(VideoDataContext);
    const {globalCountdown, setGlobalCountdown} = useContext(VideoDataContext);
    const [user, setUser] = useState(null);
    const [localVideoData, setLocalVideoData] = useState(videoData);
    const [lang, setLang] = useState('en');
    const [message, setMessage] = useState('');
    const [promo, setPromo] = useState('');
    const [montantPromo, setMontantPromo] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [totalPriceInMatic, setTotalPriceInMatic] = useState(0);
    const [intervalId, setIntervalId] = useState(null);
    const [web3, setWeb3] = useState(null);
    const [contract, setContract] = useState(null);

    useEffect(() => {
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
            setUser(JSON.parse(storedUser));
        }
    }, []);
    useEffect(() => {
        setLocalVideoData(videoData);
        if (videoData.length > 0) {
            setLang(videoData[0].lang);
            let newEuro = 0;
            let newMatic = 0;
            videoData.map((video) => {
                newEuro += parseFloat(video.price);
                newMatic += parseFloat(video.priceInMatic);
            });
            setTotalPrice(newEuro);
            setTotalPriceInMatic(newMatic);
        }

        // Si une nouvelle vidéo est ajoutée, augmentez le compteur global de 15 minutes
        if (videoData.length > localVideoData.length) {
            setGlobalCountdown(prevCountdown => prevCountdown + 15 * 60 * 1000); // 15 minutes en millisecondes
        }

    }, [videoData, props.openShop]);
    useEffect(() => {
        if (videoData.length > 0) {
            const interval = setInterval(() => {
                setGlobalCountdown(prevCountdown => {
                    if (prevCountdown > 0) {
                        return prevCountdown - 1000;
                    } else {
                        if (user) {
                            // Faire une requête axios
                            const url = import.meta.env.VITE_BACKEND_URL + "/gerance/resetShopBuy";
                            axios.post(url, {
                                videoData: videoData,

                            }, {
                                headers: {
                                    Authorization: "Bearer " + user.token,
                                },
                            }).then(response => {
                                if (response.data) {
                                    setTotalPrice(0);
                                    setTotalPriceInMatic(0);
                                }
                            })
                                .catch(error => {
                                    console.error('Il y avait une erreur!', error);
                                });

                            return 0;
                        }
                    }
                });
            }, 1000);
            setIntervalId(interval);
            return () => clearInterval(interval);
        }
    }, [user, videoData]);
    useEffect(() => {
        if (window.ethereum) {
            const web3Instance = new Web3(window.ethereum);
            setWeb3(web3Instance);

            const contractInstance = new web3Instance.eth.Contract(CONTRACT_ABI, CONTRACT_ADDRESS);
            setContract(contractInstance);
        } else {
            alert('Veuillez installer MetaMask !');
        }
    }, []);
    const resetContextAndLocalStorage = () => {
        // Vider le contexte
        setVideoData([]);
        setGlobalCountdown(0);

        // Vider le localStorage
        localStorage.removeItem('videoData');
        localStorage.removeItem('globalCountdown');
        localStorage.removeItem('intervalId');
    };
    const handleAddPromo = () => {
        console.log(promo);
        axios.post(import.meta.env.VITE_BACKEND_URL + "/gerance/promo", {
            promo: promo,
        }, {
            headers: {
                Authorization: "Bearer " + user.token,
            },
        }).then(response => {
            if (response.data !== null) {
                let reduction = parseInt(response.data.reduction);
                setMontantPromo(reduction);
                reduction = (100 - reduction) / 100;
                setTotalPrice(totalPrice * reduction);
                setTotalPriceInMatic(totalPriceInMatic * reduction);
                setPromo('');
            }
        }).catch(error => {
            console.error('Il y avait une erreur!', error);
        })
    }
    const handlePaiement = async () => {
        //alert("Cette fonctionalité n'est pas encore disponible");
        try {
            await contractInstance.methods.buy(videoId).send({from: fromAddress});
        } catch (error) {
            console.error("Erreur lors de l'achat du NFT", error);
        }
    };
    const buyOrMintVideo = async (videoId, fromAddress, videoURI, startTime, endTime, creatorAddress, priceInEther, id) => {
            if (!web3 || !contract) return;
                //alert("Cette fonctionalité n'est pas encore disponible");
            try {
                const priceInWei = web3.utils.toWei(priceInEther.toString(), 'ether');
                const gasAmount = await contract.methods.mint(videoURI, startTime, endTime,
                    creatorAddress, fromAddress).estimateGas({from: fromAddress, value: priceInWei});
                if (videoId !== null) {
                    // Vérifiez si le segment vidéo a déjà été acheté
                    const videoPurchased = await contract.methods.videoPurchased(videoId).call();
                    if (videoPurchased) {
                        // Si le segment vidéo a déjà été acheté, exécutez la fonction buyVideo
                        await contract.methods.buyVideo(videoId).send({
                            from: fromAddress,
                            value: priceInWei,
                            gas: gasAmount
                        });
                    }
                } else {
                    // Si le segment vidéo n'a jamais été acheté, exécutez la fonction mint
                    contract.methods.mint(videoURI, startTime, endTime, creatorAddress, fromAddress)
                        .send({from: fromAddress, value: priceInWei, gas: gasAmount})
                        .then(receipt => {
                            const TransferEvent = receipt.events.Transfer;
                            const newTokenId = TransferEvent.returnValues.tokenId;
                            const url = import.meta.env.VITE_BACKEND_URL + '/gerance/updateMintId';
                            axios.post(url, {
                                newTokenId: newTokenId,
                                startTime: startTime,
                                id: id
                            }, {
                                headers: {
                                    Authorization: "Bearer " + user.token,
                                },
                            }).then(response => {
                                if (response.data) {
                                    resetContextAndLocalStorage();
                                }
                            }).catch(error => {
                                console.error('on ne recupere pas le token video', error);
                            })
                        }).catch(error => {
                        console.error('Une erreur est survenue lors de la création du jeton', error);
                    });
                }
            } catch
                (error) {
                console.error('Une erreur est survenue lors de l\'achat ou de la création de la vidéo', error);
            }
        }
    const getCreatorAccount = async (video) => {
        try {
            const url = import.meta.env.VITE_BACKEND_URL + '/gerance/creator';
            let creatorAccount = null;
            await axios.post(url, {
                id: video.idVideo,
                startTime: video.startTime
            }, {
                headers: {
                    Authorization: "Bearer " + user.token,
                },
            }).then(response => {
                console.log('Compte du créateur récupéré avec succès', response.data);
                creatorAccount = response.data;
            }).catch(error => {
                console.error('Une erreur est survenue lors de la récupération du compte du créateur', error);
            });
            return creatorAccount;
        } catch (error) {
            console.error('Une erreur est survenue lors de la récupération du compte du créateur', error);
        }
    };
    const handleBuyClick = async () => {
        //alert("Cette fonctionalité n'est pas encore disponible");
        let accounts = await window.ethereum.request({method: 'eth_requestAccounts'});
        let account = accounts[0];
        console.log(account);
        for (let index = 0; index < localVideoData.length; index++) {
            let videoId, videoURI, creatorAddress;
            const video = localVideoData[index];
            const id = video.idVideo;
            const recup = await getCreatorAccount(video);
            console.log('recup:', recup);
            if (recup && 'idMint' in recup && 'token' in recup && 'compte' in recup) {
                videoId = recup.idMint; // L'identifiant de la vidéo
                videoURI = recup.token; // L'URI de la vidéo
                let bytes = CryptoJS.AES.decrypt(recup.compte, import.meta.env.VITE_DECRYPTION_KEY);
                creatorAddress = bytes.toString(CryptoJS.enc.Utf8); // L'adresse du créateur de la vidéo
            } else {
                console.error('Erreur : recup est indéfini ou ne contient pas les propriétés attendues');
            }
            const fromAddress = account; // Remplacez par l'adresse de l'acheteur
            const startTime = video.startTime; // Le temps de début de la vidéo
            const endTime = video.endTime; // Le temps de fin de la vidéo
            const priceInEther = 1;
            buyOrMintVideo(videoId, fromAddress, videoURI, startTime, endTime, creatorAddress, priceInEther, id);
        }
    };
    const removeVideo = (index) => {
        const newVideoData = [...localVideoData];
        newVideoData.splice(index, 1);
        setLocalVideoData(newVideoData);

        if (newVideoData.length === 0 && intervalId) {
            clearInterval(intervalId);
            setGlobalCountdown(0);
        }
    };
    const handleChangeMessage = (value, i) => {
        setLocalVideoData(prevVideoData => {
            const newVideoData = [...prevVideoData];
            newVideoData[i].message = value;
            return newVideoData;
        });
    };

    function formatTime(ms) {
        const totalSeconds = Math.floor(ms / 1000);
        const totalMinutes = Math.floor(totalSeconds / 60);
        const totalHours = Math.floor(totalMinutes / 60);

        const seconds = totalSeconds % 60;
        const minutes = totalMinutes % 60;
        const hours = totalHours;

        return `${hours}h ${minutes}m ${seconds}s`;
    }

    return (
        <div className="pageshop">
            <div className="block_panier">
                <div className="headerShop">
                    <h2>Votre panier</h2>
                    <p className="timer_nft">Nombre de transactions sur le portefeuille : {videoData.length}</p>
                    <p className="timer_nft">{formatTime(globalCountdown)}</p>
                    <p>{localVideoData.length} items</p>
                </div>
                <div className="bodyShop">
                    {
                        localVideoData.length > 0 &&
                        localVideoData.map((video, index) => (
                            <div key={index} className="lineShop">
                                <div className="line_internal">
                                    <div className="removeNtf" onClick={() => removeVideo(index)}><img src={cross}
                                                                                                       alt=""/>
                                    </div>
                                    <div className="video_img">
                                        <img src={video.poster}/>
                                        <p className="time">{parseInt(video.endTime) - parseInt(video.startTime)}s.</p>
                                        <div className="onde_small">
                                            <img src={ondeSmall} alt=""/>
                                        </div>
                                    </div>
                                    <div className="creator">
                                        <p>{video.title}</p>
                                        <p>{video.creator}</p>
                                    </div>
                                    <div className="price">
                                        <p>{video.price}€</p>
                                        <p>{video.priceInMatic} MATIC</p>
                                    </div>
                                </div>
                                <div className="line_legend">
                                    <label htmlFor="message">Ajouter une légende</label>
                                    <input value={video.message}
                                           onChange={(e) => handleChangeMessage(e.target.value, index)} type="text"
                                           name="message"/>
                                </div>
                            </div>
                        ))
                    }
                </div>
                <div className="footerShop">
                    <div className="promo_code">
                        <label htmlFor="promo">Code Promo</label>
                        <input type="text" name="promo" value={promo} onChange={(e) => setPromo(e.target.value)}/>
                        <div className="promo_btn" onClick={handleAddPromo}>
                            <img src={send} alt=""/>
                        </div>
                    </div>
                    {
                        montantPromo > 0 &&
                        <div className="promo_applied">
                            <p>Code Promo appliqué</p>
                            <p>-{montantPromo}%</p>
                        </div>
                    }
                    <div className="total_price">
                        <div className="left">
                            <p>Prix Total</p>

                        </div>
                        <div className="right">
                            <p>{totalPrice} €</p>
                            <p>{totalPriceInMatic} MATIC</p>
                        </div>
                    </div>
                    <button onClick={handleBuyClick}>
                        <img src={lang === "fr" ? continu : continuEn}/>
                    </button>
                </div>
            </div>
        </div>
    );
}

export default ShopPage;
