import React, {Component} from "react";
import {Link} from "react-router-dom";
import axios from "axios";
import Web3 from "web3";
import CryptoJS from 'crypto-js';
import FormControlLabel from "@mui/material/FormControlLabel";
import MuiSwitch from "../../modules/MuiSwitch";
import NavigateBackButton from "../../modules/NavigateBackButton.jsx";
import continuerButton from "../../../../assets/logos/changeValidButton.svg";
import changeValidButtonen from "../../../../assets/logos/en/changeValidButtonen.svg";
import uploadVideoIcon from "../../../../assets/logos/noun-upload-3778990.svg";
import vignette1 from "../../../../assets/images/vignette1.jpg";
import {bannerHeight} from "../../modules/Helper.jsx";
import MenuUpload from "../../modules/MenuUpload.jsx";
import VideoUpload from "../../modules/VideoUpload.jsx";
import ModalWallet from "../../modules/ModalWallet.jsx";

import en from '../../../../locales/en/translation.json';
import fr from '../../../../locales/fr/translation.json';

// Pour le réseau principal de Polygon (Mainnet)
//const web3 = new Web3('https://polygon-mainnet.infura.io/v3/145ed7b2b61e42729c90d6d31cc54c29');

// Pour le réseau de test de Polygon (Mumbai)
const web3 = new Web3('https://polygon-mumbai.infura.io/v3/145ed7b2b61e42729c90d6d31cc54c29');

export default class UploadVideo_part5 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            marginTopValue: "18rem",
            sendLauch: false,
            user: null,
            artistChoiceOptions: [],

            allow_NFT_Creation: false,
            upload_artistChoiceOptions: "",
            plageSelect: false,
            openConnectWallet: false,
            compte: null,
            message: "",

            lang: 'en',
            trad: en,

            plages: [
                {startTime: "0:0", endTime: "0:0", price: 0}
            ],
        };
        this.connectWallet = this.connectWallet.bind(this);

    }

    componentDidMount() {
        if (JSON.parse(localStorage.getItem("user"))) {
            this.setState({
                user: JSON.parse(localStorage.getItem("user")),
            }, () => {
                let lang = this.state.user.user.language;
                if (lang === "fr") {
                    this.setState({lang: "fr", trad: fr});
                }
            });
        }

        document.title = "Upload Video - NFT | Wonderplace";
        this.handleResize();
        window.addEventListener("resize", this.handleResize);
        bannerHeight();
        if (localStorage.getItem('storageType')) {
            let user = localStorage.getItem("user");
            if (user !== null) {
                this.setState({
                    user: JSON.parse(user),
                }, () => {
                    let testUrl = import.meta.env.VITE_BACKEND_URL + "/compte/4goKWsygARW3aqM1eqQkdGrxKKNWnjdSZOHr8ulEyDdW5VbuSKlA0bTspozEFi77";
                    axios.post(testUrl, {
                        id: this.state.user.user.id,
                    }, {
                        headers: {
                            Authorization: "Bearer " + this.state.user.token,
                        },
                    }).then(res => {
                        console.log(res.data);
                        this.setState({compte: res.data});
                    }).catch(err => {
                        console.log(err);
                    });
                    let video = localStorage.getItem('currentVideo');
                    video = JSON.parse(video);
                    let urlVideo = import.meta.env.VITE_BACKEND_URL + "/creator/getVideo";
                    axios.post(urlVideo, {
                        id: video.id,
                    }, {
                        headers: {
                            Authorization: "Bearer " + this.state.user.token,
                        },
                    }).then(res => {
                        this.setState({
                            video: res.data,
                        }, () => {
                            let plages = [];
                            let plageSelect = false;
                            console.log(res.data);
                            if (res.data.plages.length > 0) {
                                plageSelect = true;
                                res.data.plages.map(value => {
                                    let minutesStart = Math.floor(value.startTime / 60);
                                    let secondStart = value.startTime % 60;
                                    let timeStart = minutesStart + ":" + secondStart;

                                    let minutesEnd = Math.floor(value.endTime / 60);
                                    let secondEnd = value.endTime % 60;
                                    let timeEnd = minutesEnd + ":" + secondEnd;

                                    let obj = {
                                        startTime: timeStart,
                                        endTime: timeEnd,
                                        label: "Disponible",
                                        price: value.price,
                                    }
                                    plages.push(obj);
                                })
                            } else {
                                plages.push({startTime: "0:0", endTime: "0:0", price: 0});
                            }
                            this.setState({plages, plageSelect, allow_NFT_Creation: res.data.nft === 1 ? true : false});
                        });
                    }).catch(err => {
                        console.log(err);
                    })
                    let url = import.meta.env.VITE_BACKEND_URL + "/upload/part5";
                    axios.get(url, {
                        headers: {
                            Authorization: "Bearer " + this.state.user.token,
                        },
                    }).then(res => {
                        this.setState({artistChoiceOptions: res.data});
                    }).catch(err => {
                        console.log(err);
                    })
                });
            } else {
                location.assign("/");
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    handleResize = () => {
        const resolution = window.innerWidth;

        // Vérifier si la résolution est inférieure ou égale à 1280 pixels
        if (resolution < 1280) {
            // Calculer la nouvelle valeur "top" en fonction de la résolution
            const newMarginTopValue = 18 + resolution / 100; // Par exemple

            // Mettre à jour la valeur "MarginTop" dans le state
            this.setState({
                marginTopValue: newMarginTopValue + "rem",
            });
        } else {
            this.setState({
                marginTopValue: 0 + "rem",
            });
        }
    };
    /*
    handleOptionChange_artistChoiceOptions = (value) => {
        this.setState({upload_artistChoiceOptions: value});
    };
    */
    handleChange = async (event) => {
        if (event.target.checked === true) {
            let isWallet = await this.connectWallet();
            if (isWallet) {
                this.setState({
                    allow_NFT_Creation: true,
                });
            } else {
                this.setState({openConnectWallet: true});
            }
        }
    };
    handleUploadContinue = async (e) => {
        e.preventDefault();
        let {allow_NFT_Creation, upload_artistChoiceOptions, plages} = this.state;
        this.setState({sendLauch: true});
        let newPlages = [];
        if (allow_NFT_Creation) {
            if (this.state.plageSelect) {
                plages.map(value => {
                    let tabStart = value.startTime.split(':');
                    let tabEnd = value.endTime.split(':'); // 1:15
                    let timeStart = (parseInt(tabStart[0]) * 60) + parseInt(tabStart[1]);
                    let timeEnd = (parseInt(tabEnd[0]) * 60) + parseInt(tabEnd[1]);
                    let obj = {
                        startTime: timeStart,
                        endTime: timeEnd,
                        label: "Disponible",
                        price: value.price,
                    }
                    newPlages.push(obj);
                });
                this.setState({plages}, () => {
                    const url = import.meta.env.VITE_BACKEND_URL + "/upload/savePart5";
                    axios.post(url, {
                        upload_artistChoiceOptions: parseInt(upload_artistChoiceOptions),
                        nft: allow_NFT_Creation === true ? 1 : 0,
                        plages:newPlages,
                        id: this.state.video.id,
                    }, {
                        headers: {
                            Authorization: "Bearer " + this.state.user.token,
                        },
                    }).then(res => {
                        if (res.data) {
                            this.setState({sendLauch: false});
                            localStorage.setItem('currentVideo', JSON.stringify(res.data));
                            localStorage.setItem('editvideo', 'editvideo');
                            location.assign("/gestion-de-ma-page/upload-video-part6");
                        }
                    }).catch(err => {
                        console.log(err);
                    })
                })

            } else {
                let time = this.state.video.time;
                time = time.split(':');
                time = parseInt(time[0]) * 60 + parseInt(time[1]);
                time = Math.floor(time / 5) * 5;
                for (let i = 0; i < time; i += 5) {
                    let obj = {
                        startTime: i,
                        endTime: i + 5,
                        label: "Disponible",
                        price: value.price,
                    }
                    newPlages.push(obj);
                }
                this.setState({plages}, () => {
                    const url = import.meta.env.VITE_BACKEND_URL + "/upload/savePart5";
                    axios.post(url, {
                        upload_artistChoiceOptions: parseInt(upload_artistChoiceOptions),
                        nft: allow_NFT_Creation === true ? 1 : 0,
                        plages: newPlages,
                        id: this.state.video.id,
                    }, {
                        headers: {
                            Authorization: "Bearer " + this.state.user.token,
                        },
                    }).then(res => {
                        if (res.data) {
                            this.setState({sendLauch: false});
                            localStorage.setItem('currentVideo', JSON.stringify(res.data));
                            localStorage.setItem('editvideo', 'editvideo');
                            location.assign("/gestion-de-ma-page/upload-video-part6");
                        }
                    }).catch(err => {
                        console.log(err);
                    })
                })
            }
        }
    };
    handleAddPlage = () => {
        this.setState(prevState => ({
            plages: [...prevState.plages, {startTime: "0:0", endTime: "0:0"}]
        }));
    }
    handleNewMinutePlage = (temp, type, index) => {
        let {plages} = this.state;
        if (type === 'first') {
            let tab = plages[index].startTime.split(':');
            tab[0] = temp;
            plages[index].startTime = "" + tab[0] + ":" + tab[1];
        } else {
            let tab = plages[index].endTime.split(':');
            tab[0] = temp;
            plages[index].endTime = "" + tab[0] + ":" + tab[1];
        }
        this.setState({plages});
    }
    handleNewSecondPlage = (temp, type, index) => {
        let {plages} = this.state;
        if (type === 'first') {
            let tab = plages[index].startTime.split(':');
            tab[1] = temp;
            plages[index].startTime = "" + tab[0] + ":" + tab[1];
        } else if (type === 'price') {
            plages[index].price = temp;
        } else {
            let tab = plages[index].endTime.split(':');
            tab[1] = temp;
            plages[index].endTime = "" + tab[0] + ":" + tab[1];
        }
        this.setState({plages});
    }
    handleDeletePlage = (index) => {
        let {plages, video} = this.state;
        const url = import.meta.env.VITE_BACKEND_URL + "/upload/deletePlage";
        axios.post(url, {
            id: video.id,
            plage: plages[index],
        }, {
            headers: {
                Authorization: "Bearer " + this.state.user.token,
            },
        }).then(res => {
            if (res.data) {
                let plages = [];
                res.data.map(value => {
                    let minutesStart = Math.floor(value.startTime / 60);
                    let secondStart = value.startTime % 60;
                    let timeStart = minutesStart + ":" + secondStart;

                    let minutesEnd = Math.floor(value.endTime / 60);
                    let secondEnd = value.endTime % 60;
                    let timeEnd = minutesEnd + ":" + secondEnd;

                    let obj = {
                        startTime: timeStart,
                        endTime: timeEnd,
                        label: "Disponible"
                    }
                    plages.push(obj);
                })
                this.setState({plages});
            }
        }).catch(err => {
            console.log(err);
        })
    }

    async connectWallet() {
        let key = import.meta.env.VITE_DECRYPTION_KEY;
        let url = import.meta.env.VITE_BACKEND_URL + "/compte/r8ulEyDdW5VbuSKlA0bTspozEFi774goKWsygARW3aqM1eqQkdGrxKKNWnjdSZOH";
        if (window.ethereum) { // Vérifie si MetaMask est installé
            try {
                let accounts = await window.ethereum.request({method: 'eth_requestAccounts'});
                let account = accounts[0];
                console.log(account);
                if (!this.state.compte) {
                    try {
                        let cryptoAccounts = CryptoJS.AES.encrypt(account, key).toString();
                        axios.post(url, {
                            test: cryptoAccounts,
                            id: this.state.user.user.id,
                        }, {
                            headers: {
                                Authorization: "Bearer " + this.state.user.token,
                            },
                        }).then(res => {
                            if (res.data) {
                                this.setState({
                                    compte: cryptoAccounts,
                                    message: "Votre wallet est connecté",
                                }, () => {
                                    setTimeout(() => {
                                        this.setState({message: ""});
                                    }, 3000);
                                });
                            }
                        }).catch(err => {
                            console.log(err);
                        })
                    } catch (error) {
                        console.log(error);
                    }
                }
                return true;
            } catch (error) {
                console.error("L'utilisateur a refusé la connexion au portefeuille");
                return false;
            }
        } else {
            console.log("Veuillez installer MetaMask !");
            return false;
        }
    }

    callBackCloseModalWallet = () => {
        this.setState({openConnectWallet: false});
    }

    render() {
        return (
            <>
                {
                    this.state.message !== "" &&
                    <div className="message success">
                        <p>{this.state.message}</p>
                    </div>
                }
                {
                    this.state.openConnectWallet &&
                    <ModalWallet callBackCloseModalWallet={this.callBackCloseModalWallet}/>
                }
                <div className="uploadVideoGlobal_Part5">
                    <div className="uploadHeader">
                        <h1>{this.state.trad.creatorUploadVideo}</h1>
                        <MenuUpload active={5}/>
                    </div>

                    <div className="uploadContainer">
                        <section
                            className="left"
                            style={{marginTop: this.state.marginTopValue}}
                        >
                            <div className="notif">
                                <p className="description_text">{this.state.trad.nftDescriptionText}</p>
                                <div className="switchContainer">
                                    <span className="noNotif">{this.state.trad.spanNoNotif}</span>
                                    <FormControlLabel
                                        control={
                                            <MuiSwitch
                                                size="small"
                                                checked={this.state.allow_NFT_Creation}
                                                onChange={this.handleChange}
                                                name="allow_NFT_Creation"
                                            />
                                        }
                                    />
                                    <span className="yesNotif">{this.state.trad.spanYesNotif}</span>
                                </div>
                            </div>

                            <div className="upload_TextBloc">
                                <h3>{this.state.trad.uploadTextBloc}</h3>
                                <p>{this.state.trad.uploadTextBloc2}</p>
                                <p>{this.state.trad.uploadTextBloc5}</p>
                            </div>
                            {
                                this.state.allow_NFT_Creation &&
                                <div className="upload_TextBloc">
                                    <div style={{display: 'flex'}}>
                                        <FormControlLabel
                                            control={
                                                <MuiSwitch
                                                    size="small"
                                                    checked={this.state.plageSelect}
                                                    onChange={(e) => this.setState({plageSelect: e.target.checked})}
                                                    name="allow_NFT_Creation"
                                                />
                                            }
                                        />
                                        <p>{this.state.trad.uploadTextBloc6}</p>
                                    </div>
                                </div>
                            }
                            <div className="upload_TextBloc">
                                <h3>{this.state.trad.uploadTextBloc3}</h3>
                                <p>{this.state.trad.uploadTextBloc4}</p>
                            </div>
                            {/*
                            <div className="upload_Select">
                                <label htmlFor="upload_artistChoice">{this.state.trad.uploadArtistChoice}</label>
                                <select
                                    id="upload_artistChoice"
                                    name="upload_artistChoice"
                                    value={this.state.upload_artistChoiceOptions}
                                    onChange={(e) => this.handleOptionChange_artistChoiceOptions(e.target.value)}
                                >
                                    <option value="default">{this.state.trad.selectOtherArtiste}</option>
                                    {
                                        this.state.artistChoiceOptions[0] &&
                                        this.state.artistChoiceOptions.map((option) => (
                                            <option
                                                name="upload_artistChoice_option"
                                                key={option.id}
                                                value={option.id}
                                            >
                                                {option.nom_artiste}
                                            </option>
                                        ))
                                    }
                                </select>
                            </div>
                            */}
                        </section>

                        <section className="right">
                            <div className="upload_VignetteSection">
                                <div className="upload_VignetteText">
                                    {
                                        this.state.video &&
                                        <div className="vignette">
                                            <VideoUpload
                                                indexVideo={0}
                                                upload={false}
                                                id={this.state.video.id}
                                                video={import.meta.env.VITE_BACKEND_URL_IMG + this.state.video.path_nocompres}
                                            />
                                        </div>
                                    }
                                </div>
                            </div>
                            {
                                this.state.plageSelect &&
                                <div className="plages">
                                    <div className="upload_TextBloc">
                                        <p>{this.state.trad.plageExplication}</p>
                                    </div>
                                    <div className="multiplePlageSelect">
                                        {
                                            this.state.plages.map((value, index) => (
                                                <div key={index}>
                                                    <h2>Instant {index + 1}</h2>
                                                    <div className="line">
                                                        <div className="left_line">
                                                            <div className="form">
                                                                <label htmlFor="minute">{this.state.trad.minute}</label><br/>
                                                                <input type="text" placeholder={this.state.trad.minute}
                                                                       value={value.startTime.split(':')[0]}
                                                                       onChange={(e) => this.handleNewMinutePlage(e.target.value, 'first', index)}/>
                                                            </div>
                                                            <div className="form">
                                                                <label
                                                                    htmlFor="seconde">{this.state.trad.seconde}</label><br/>
                                                                <input type="text" placeholder={this.state.trad.seconde}
                                                                       value={value.startTime.split(':')[1]}
                                                                       onChange={(e) => this.handleNewSecondPlage(e.target.value, 'first', index)}/>
                                                            </div>
                                                        </div>
                                                        <div className="center_line">
                                                            <p>{this.state.trad.a}</p>
                                                        </div>
                                                        <div className="right_line">
                                                            <div className="form">
                                                                <label htmlFor="minute">{this.state.trad.minute}</label><br/>
                                                                <input type="text" placeholder={this.state.trad.minute}
                                                                       value={value.endTime.split(':')[0]}
                                                                       onChange={(e) => this.handleNewMinutePlage(e.target.value, 'last', index)}/>
                                                            </div>
                                                            <div className="form">
                                                                <label
                                                                    htmlFor="seconde">{this.state.trad.seconde}</label><br/>
                                                                <input type="text" placeholder={this.state.trad.seconde}
                                                                       value={value.endTime.split(':')[1]}
                                                                       onChange={(e) => this.handleNewSecondPlage(e.target.value, 'last', index)}/>
                                                            </div>
                                                        </div>
                                                        <div className="center_line">
                                                            <p>{this.state.trad.pour}</p>
                                                        </div>
                                                        <div className="price_line">
                                                            <div className="form">
                                                                <label
                                                                    htmlFor="seconde">{this.state.trad.price} €</label><br/>
                                                                <input type="text" placeholder={this.state.trad.price}
                                                                       value={value.price}
                                                                       onChange={(e) => this.handleNewSecondPlage(e.target.value, 'price', index)}/>
                                                            </div>
                                                        </div>
                                                        <div className="delete">
                                                            <button onClick={() => this.handleDeletePlage(index)}>-
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    <button onClick={this.handleAddPlage}>+</button>
                                </div>
                            }
                        </section>
                    </div>

                    <div className="navButtons">
                        <NavigateBackButton/>

                        <div className="form_submit_container">
                            {this.state.sendLauch ? (
                                <div className="lds-ring">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            ) : (
                                <div className="form_submit">
                                    <img
                                        src={this.state.lang === "fr" ? continuerButton : changeValidButtonen}
                                        alt="continuer"
                                        className="continuer"
                                        onClick={this.handleUploadContinue}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
