import React, {useState, useEffect} from 'react';
import axios from "axios";


const VideoDataContext = React.createContext({
    videoData: [],
    setVideoData: () => {
    },
    globalCountdown: 0,
    setGlobalCountdown: () => {
    },
    intervalId: null,
    setIntervalId: () => {
    },
});

export const VideoDataProvider = ({children}) => {
    const [videoData, setVideoData] = useState(() => {
        const localData = localStorage.getItem('videoData');
        return localData ? JSON.parse(localData) : [];
    });
    const [globalCountdown, setGlobalCountdown] = useState(() => {
        const localData = localStorage.getItem('globalCountdown');
        return localData !== null && localData !== 'undefined' ? JSON.parse(localData) : 0;
    });
    const [intervalId, setIntervalId] = useState(() => {
        const localData = localStorage.getItem('intervalId');
        return localData ? JSON.parse(localData) : null;
    });
    const [startTime, setStartTime] = useState(Date.now());

    useEffect(() => {
        localStorage.setItem('videoData', JSON.stringify(videoData));
    }, [videoData]);

    useEffect(() => {
        localStorage.setItem('globalCountdown', JSON.stringify(globalCountdown));
    }, [globalCountdown]);

    useEffect(() => {
        localStorage.setItem('intervalId', JSON.stringify(intervalId));
    }, [intervalId]);

    useEffect(() => {
        setStartTime(Date.now());
        const interval = setInterval(() => {
            setGlobalCountdown(prevCountdown => {
                const elapsedTime = (Date.now() - startTime) / 1000; // Convertir en secondes
                let newCountdown = prevCountdown - elapsedTime;
                if (newCountdown < 0) {
                    newCountdown = 0;
                }
                if (newCountdown > 0) {
                    return newCountdown;
                } else {
                    // Récupérer l'utilisateur du localStorage
                    if(localStorage.getItem('user')) {
                        const user = JSON.parse(localStorage.getItem('user'));
                        if (videoData.length !== 0) {
                            // Appel à l'API ici
                            const url = import.meta.env.VITE_BACKEND_URL + "/gerance/resetShopBuy";
                            axios.post(url, {
                                videoData: videoData,
                            }, {
                                headers: {
                                    Authorization: "Bearer " + user.token,
                                },
                            }).then(response => {
                                if (response.data) {
                                    setVideoData([]);
                                    clearInterval(interval);
                                    localStorage.removeItem('videoData');
                                    localStorage.removeItem('globalCountdown');
                                    localStorage.removeItem('intervalId');
                                }
                            })
                                .catch(error => {
                                    console.error('Il y avait une erreur!', error);
                                });

                            return 0;
                        }
                    }
                }
            });
        }, 1000);
        setIntervalId(interval);
        return () => clearInterval(interval);
    }, [globalCountdown]);

    return (
        <VideoDataContext.Provider
            value={{videoData, setVideoData, globalCountdown, setGlobalCountdown, intervalId, setIntervalId}}>
            {children}
        </VideoDataContext.Provider>
    );
};
export default VideoDataContext;
