import React, {useEffect,useState} from 'react';

import cross from '../../../assets/logos/crossIcon.svg';
import meta from '../../../assets/wallet/MetaMask_Fox.svg.png';

import en from '../../../locales/en/translation.json';
import fr from '../../../locales/fr/translation.json';

const ModalWallet = ({callBackCloseModalWallet}) => {
    const [trad, setTrad] = useState(en);
    useEffect(() => {
        if (localStorage.getItem('user')) {
            if (JSON.parse(localStorage.getItem('user')).user.language === 'fr') {
                setTrad(fr)
            }
        }
    }, []);

    return (
        <>
            {
                trad !== undefined &&
                <div className="modalWallet">
                    <div className="modal_block">
                        <div className="header">
                            <div className="close">
                                <img onClick={callBackCloseModalWallet} src={cross} alt="close"/>
                            </div>
                            <h1>{trad.walletModalTitle}</h1>
                        </div>
                        <div className="body">
                            <div className="left">
                                <h2>{trad.walletModalLeftp1}</h2>
                                <h2>{trad.walletModalLeftp2}</h2>
                                <p><a href="">{trad.walletModalLeftQ1}</a></p>
                                <p><a href="">{trad.walletModalLeftQ2}</a></p>
                                <p><a href="">{trad.walletModalLeftQ3}</a></p>
                            </div>
                            <div className="spacer"></div>
                            <div className="right">
                                <h2>{trad.walletModalRightp1}</h2>
                                <h2>{trad.walletModalRightp2}</h2>
                                <a target="_blank" href="https://metamask.io/">{trad.walletModalRightInstall} <img
                                    src={meta} alt=""/></a>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}
export default ModalWallet;
