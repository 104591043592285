import React, {useEffect, useState, useRef, useContext} from 'react';
import axios from 'axios';

import VideoDataContext from '../../VideoDataContext';

import onde_blue from "../../../assets/videos/onde_blue.svg";
import onde_red from "../../../assets/videos/onde_red.svg";
import onde_white from "../../../assets/videos/onde_white.svg";
import rectangle from "../../../assets/videos/rectangle.svg";
import forward30 from "../../../assets/logos/player/forward_30.svg";
import forward5 from "../../../assets/logos/player/forward_5.svg";
import backward30 from "../../../assets/logos/player/backward_30.svg";
import backward5 from "../../../assets/logos/player/backward_5.svg";
import ModalWallet from "./ModalWallet.jsx";

import en from '../../../locales/en/translation.json';
import fr from '../../../locales/fr/translation.json';

import accepten from "../../../assets/logos/en/continuerButtonen.svg";
import cross from "../../../assets/logos/crossIcon.svg";
import accept from "../../../assets/logos/continuerButton.svg";
import ondeSmall from "../../../assets/wallet/ondeSmall.svg";

const VideoBarNft = ({videosToBuy, videosBought, time, isPlaying, videoRef, lang, user, video}) => {
    const [tabNB, setTabNB] = useState([]);
    const [currentInterval, setCurrentInterval] = useState(0);
    const [openConnectWallet, setOpenConnectWallet] = useState(false);
    const [openMessageNft, setOpenMessageNft] = useState(false);
    const [message, setMessage] = useState("");
    const [idPlage, setIdPlage] = useState(0);
    const [clickAllowed, setClickAllowed] = useState(false);
    const {videoData, setVideoData} = useContext(VideoDataContext);
    const [timeInstant, setTimeInstant] = useState(0);
    const [priceInstant, setPriceInstant] = useState(0);
    const [priceInstantEuro, setPriceInstantEuro] = useState(0);
    const [openMessagePanier, setOpenMessagePanier] = useState(false);

    const trad = lang === "en" ? en : fr;

    const barRef = useRef();
    const videoElementRef = useRef(null);
    let previousInterval = useRef(0);

    let fullbar = null;
    let translate = null;
    let widthTotal = null;

    useEffect(() => {
        const calculateWidthTotal = async () => {
            fullbar = document.querySelector('.fullbar').getBoundingClientRect().width / 2;
            fullbar = fullbar - 38;
            translate = parseInt(fullbar);
            let newNB = Math.floor(time / 5);
            widthTotal = 0;
            if (time && newNB > 0) { // Vérifiez si 'time' est défini et si 'newNB' est supérieur à 0
                for (let i = 0; i <= newNB; i++) {
                    widthTotal += 82;
                    await new Promise(resolve => setTimeout(resolve, 0)); // Ajoutez cette ligne
                }
                let lineonde = document.querySelector('.lineOnde');
                lineonde.style.width = widthTotal + 100 + "px";
                setTimeout(() => {
                    lineonde.style.opacity = 1;
                }, 100);
            }
        }
        calculateWidthTotal();
    }, [time]);
    useEffect(() => {
        let newTabNB = [];
        let newNB = Math.floor(time / 5);
        for (let i = 0; i <= newNB; i++) {
            let newEnter = {};
            let tabTime = i * 5;
            let bought = videosBought.filter(vb => parseInt(vb.startTime) === tabTime);
            let buy = videosToBuy.filter(vb => parseInt(vb.startTime) === tabTime);
            if (buy[0]) {
                newEnter.img = "blue";
            } else if (bought[0]) {
                newEnter.img = "red";
            } else {
                newEnter.img = "rien";
            }
            newEnter.time = tabTime;
            newTabNB.push(newEnter);
        }
        setTabNB(newTabNB);
        if (!barRef.current.style.transform) {
            //barRef.current.style.transform = `translateX(${translate}px)`;
            // Calculer combien de 'onde_wave' correspondent à 555px
            let totalWidth = 0;
            let waveIndex = 0;
            let ondeWaves = document.querySelectorAll('.onde_wave');
            while (totalWidth < translate && waveIndex < ondeWaves.length) {
                totalWidth += ondeWaves[waveIndex].getBoundingClientRect().width + parseInt(getComputedStyle(ondeWaves[waveIndex]).marginRight);
                waveIndex++;
            }
            const leftStyle = translate - totalWidth;
            barRef.current.style.transform = `translateX(${leftStyle}px)`;
        }
    }, [time, videosToBuy, videosBought, isPlaying]);
    useEffect(() => {
        const videoElement = videoRef.current.getElementsByTagName('video')[0];
        videoElementRef.current = videoElement;
        if (videoElement) {
            if (!barRef.current.style.transform) {
                barRef.current.style.transform = `${translate}px`;
            }

            videoElement.addEventListener("timeupdate", handleTimeUpdate);
            videoElement.addEventListener("seeked", handleSeeked);
            return () => {
                videoElement.removeEventListener("timeupdate", handleTimeUpdate);
                videoElement.removeEventListener("seeked", handleSeeked);
            };
        }
    }, [videoRef]);
    useEffect(() => {
        const currentTime = Math.floor(videoElementRef.current.currentTime / 5) * 5;
        const currentInterval = tabNB.find((value) => value.time === currentTime);

        // Mettez à jour clickAllowed en fonction de l'image actuelle
        setClickAllowed(currentInterval && currentInterval.img === "blue");
    }, [tabNB]);
    const handleSeeked = () => {
        if (videoElementRef.current && barRef.current) {
            const currentTime = videoElementRef.current.currentTime;
            previousInterval.current = Math.floor(currentTime / 5);
            let currentProgress = 0;
            let ondeWaves = document.querySelectorAll('.onde_wave');
            for (let i = 0; i < previousInterval; i++) {
                let waveWidth = ondeWaves[i].getBoundingClientRect().width;
                let marginRight = parseInt(getComputedStyle(ondeWaves[i]).marginRight);
                currentProgress += waveWidth + marginRight;
            }
            const leftStyle = translate - currentProgress;
            barRef.current.style.transform = `translateX(${leftStyle}px)`;
        }
    };
    const handleTimeUpdate = () => {
        if (barRef.current && videoElementRef.current) {
            const currentTime = Math.floor(videoElementRef.current.currentTime);
            if (currentTime !== 0) {
                if (currentTime >= previousInterval.current + 5) {
                    const leftStylePixels = barRef.current.style.transform.replace('translateX(', '').replace('px)', '');
                    const leftStyle = parseInt(leftStylePixels, 10) - 83;
                    barRef.current.style.transform = `translateX(${leftStyle}px)`;
                    previousInterval.current += 5;
                    setCurrentInterval(previousInterval.current);

                    // Get the dimensions and position of the rectangle
                    const rectangle = document.querySelector('.rectangle').getBoundingClientRect();

                    // Loop through each onde_wave
                    let ondeWaves = document.querySelectorAll('.onde_wave');
                    ondeWaves.forEach(ondeWave => {
                        // Get the dimensions and position of the onde_wave
                        const ondeWaveRect = ondeWave.getBoundingClientRect();

                        // Check if the onde_wave is inside the rectangle
                        if (ondeWaveRect.left >= rectangle.left && ondeWaveRect.right <= rectangle.right) {
                            // The onde_wave is inside the rectangle, set its opacity to 1
                            ondeWave.style.opacity = 1;
                        } else {
                            // The onde_wave is outside the rectangle, set its opacity to 0.5
                            ondeWave.style.opacity = 0.5;
                        }
                    });
                }
            }
        }
    };
    const handleForward = (scd) => {
        if (videoElementRef.current) {
            videoElementRef.current.currentTime += scd; // Move video forward by 5 seconds
            handleSeeked(); // Call handleSeeked to adjust the bar
        }
    };
    const handleBackward = (scd) => {
        if (videoElementRef.current) {
            videoElementRef.current.currentTime = Math.max(videoElementRef.current.currentTime - scd, 0); // Move video backward by 5 seconds, but not less than 0
            handleSeeked(); // Call handleSeeked to adjust the bar
        }
    };
    const handleNftLoadIsWalletConnect = async () => {
        if (!clickAllowed) {
            return;
        }
        if (window.ethereum) { // Vérifie si MetaMask est installé
            try {
                let accounts = await window.ethereum.request({method: 'eth_requestAccounts'});
                let account = accounts[0];
                if (account) {
                    const currentTime = videoElementRef.current.currentTime;
                    let startTime = Math.floor(currentTime / 5) * 5;
                    const url = import.meta.env.VITE_BACKEND_URL + "/gerance/addNftToUser";
                    axios.post(url, {
                        startTime: startTime,
                        videoId: video.id,
                    }, {
                        headers: {
                            Authorization: "Bearer " + user.token,
                        },
                    }).then(async (res) => {
                        if (res.data !== false) {
                            setIdPlage(res.data.id);
                            let newTabNB = [...tabNB];
                            for (let i = 0; i < newTabNB.length; i++) {
                                if (newTabNB[i].time === startTime) {
                                    let newObj = {...newTabNB[i]}; // Crée une nouvelle copie de l'objet
                                    newObj.img = "red";
                                    newTabNB[i] = newObj;
                                    break;
                                }
                            }
                            setTabNB(newTabNB);
                            let time = (res.data.endTime - res.data.startTime);
                            if (time < 10) {
                                time = "0" + time;
                            }
                            time = "0:" + time;
                            setTimeInstant(time);
                            let price = parseInt(res.data.price);
                            let priceInMatic = 0;
                            axios.get('https://api.coingecko.com/api/v3/simple/price?ids=matic-network&vs_currencies=eur')
                                .then(response => {
                                    if (response.data && response.data['matic-network'] && response.data['matic-network'].eur) {
                                        const eurToMaticRate = 1 / response.data['matic-network'].eur;
                                        priceInMatic = (price * eurToMaticRate).toFixed(2);

                                        setPriceInstant(priceInMatic);

                                        setPriceInstantEuro(price);


                                        setOpenMessageNft(true);
                                    } else {
                                        console.error('Unexpected API response:', response.data);
                                    }
                                })
                                .catch(error => {
                                    console.error('Error fetching EUR to MATIC rate:', error);
                                });

                        }

                    }).catch((error) => {
                        console.log(error);
                    });
                } else {
                    console.log("no account");
                }
            } catch (error) {
                console.log(error);
            }
        } else {
            setOpenConnectWallet(true);
            console.log("Veuillez installer MetaMask !");
        }
    };
    const callBackCloseModalWallet = () => {
        setOpenConnectWallet(false);
    }
    const saveMessage = async () => {
        const url = import.meta.env.VITE_BACKEND_URL + "/gerance/addMessageToNft";
        axios.post(url, {
            idPlage: idPlage,
            message: message,
        }, {
            headers: {
                Authorization: "Bearer " + user.token,
            },
        }).then((res) => {
            if (res.data) {
                setOpenMessageNft(false);
                setOpenMessagePanier(true);
                setVideoData(prevVideoData => [
                    ...prevVideoData,
                    {
                        idVideo: video.id,
                        startTime: res.data.startTime,
                        endTime: res.data.startTime + 5,
                        price: priceInstantEuro,
                        priceInMatic: priceInstant,
                        title: video.title,
                        poster: video.poster,
                        lang: lang,
                        creator: res.data.createur,
                        message: res.data.message,
                    }
                ]);
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    const closeMessageNft = () => {
        const url = import.meta.env.VITE_BACKEND_URL + "/gerance/deleteNft";
        axios.post(url, {
            idPlage: idPlage,
        }, {
            headers: {
                Authorization: "Bearer " + user.token,
            },
        }).then((res) => {
            if (res.data) {
                location.reload();
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    return (
        <>
            {
                openMessageNft &&
                <div className="modal">
                    <div className="block nftBar">
                        <div className="close" onClick={closeMessageNft}>
                            <img src={cross} alt=""/>
                        </div>
                        <h2>Ajouter une légende.</h2><br/>

                        <div className="block_img">
                            <img src={video.poster} alt=""/>
                            <p className="time">{timeInstant}</p>
                            <div className="onde_small">
                                <img src={ondeSmall} alt=""/>
                            </div>
                        </div>
                        <div className="form_controll">
                            <label htmlFor="message">Ajouter une légende</label>
                            <input onChange={(e) => setMessage(e.target.value)} type="text" name="message"
                                   value={message}/>
                        </div>
                        <div className="price_block">
                            <p className="title">Prix</p>
                            <p className="price"><span
                                className="price_euro">{priceInstantEuro}€ </span>{priceInstant} MATIC</p>
                        </div>
                        <div className="btn_block">
                            <button className="btn accept" onClick={() => saveMessage()}>
                                <img src={lang === "fr" ? accept : accepten} alt=""/>
                            </button>
                        </div>
                    </div>
                </div>

            }
            {
                openMessagePanier &&
                <div className="modal">
                    <div className="block mini openMessagePanier">
                        <div className="close" onClick={() => setOpenMessagePanier(false)}>
                            <img src={cross} alt=""/>
                        </div>
                        <h2>L'instant a été ajouté à votre panier,<br/> vous avez 15 minutes pour valider votre
                            achat.<br/> Voulez vous ...</h2>
                        <div className="btn">
                            <a href="/shop">
                                Aller au panier
                            </a>
                            <button onClick={() => setOpenMessagePanier(false)}>
                                Continuer mes achats
                            </button>
                        </div>
                    </div>
                </div>
            }
            {
                openConnectWallet &&
                <ModalWallet callBackCloseModalWallet={callBackCloseModalWallet}/>
            }
            <div className="fullbar">
                <div className="rectangle" onClick={handleNftLoadIsWalletConnect}>
                    <img src={rectangle} alt=""/>
                    <p>Acheter</p>
                </div>
                <div className="lineOnde" ref={barRef}>
                    {
                        tabNB.map((value, index) => (
                            <div className="onde_wave" key={index}>
                                {
                                    value.img === "red" &&
                                    <img src={onde_red} alt=""/>
                                }
                                {
                                    value.img === "blue" &&
                                    <img src={onde_blue} alt=""/>
                                }
                                {
                                    value.img === "rien" &&
                                    <img src={onde_white} alt=""/>
                                }
                            </div>
                        ))
                    }
                </div>
                <div className="containBtnWave">
                    <button className="btn icon" onClick={() => handleBackward(30)}>
                        <img src={backward30} alt=""/>
                    </button>
                    <button className="btn iconText" onClick={() => handleBackward(5)}>
                        <img src={backward5} alt=""/>
                        {lang === "en" ? 'Previous' : 'Précédent'}
                    </button>
                    <div className="space"></div>
                    <button className="btn iconText" onClick={() => handleForward(5)}>
                        {lang === "en" ? 'Next' : 'Suivant'}
                        <img src={forward5} alt=""/>
                    </button>
                    <button className="btn icon" onClick={() => handleForward(30)}><img src={forward30} alt=""/>
                    </button>
                </div>
            </div>
        </>
    )
}

export default VideoBarNft;
