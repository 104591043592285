import React, {Component} from 'react';
import crossIcon from "../../../assets/logos/crossIcon.svg";
import ajouter from "../../../assets/logos/accepter_btn.svg";
import ajouter_btn from "../../../assets/logos/en/accept.svg"

import en from '../../../locales/en/translation.json';
import fr from '../../../locales/fr/translation.json';

export default class SmallModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            messageSend:"",
            titre:this.props.title,
            label:this.props.label,
            lang:'en',
            trad:en,
        }
    }
    handleSend = () => {
        this.props.callbackSend();
    }

    render() {
        return (
            <div className="modal">
                <div className="block mini mini2">
                    <div className="closeModal">
                        <img
                            className="crossIcon"
                            src={crossIcon}
                            alt="crossIcon"
                            onClick={this.props.handleCloseModal}
                        />
                    </div>
                    <h2>{this.state.titre}</h2>
                            <h2 htmlFor="">{this.state.label}</h2>

                    <div className="form_submit" style={{width:"180px"}}>
                        <img
                            src={this.state.lang === "fr" ? ajouter : ajouter_btn}
                            alt="se connecter"
                            className="seconnecter"
                            onClick={this.handleSend}
                        />
                    </div>
                </div>
            </div>
        )
    }
}