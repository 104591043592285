import axios from "axios";
import React from "react";

import {Link} from "react-router-dom";

import inscriptionButton from "../../../assets/logos/inscriptionButton.svg";
import inscriptionButtonen from "../../../assets/logos/en/inscriptionButtonen.svg";

import en from '../../../locales/en/translation.json';
import fr from '../../../locales/fr/translation.json';

export default class Inscription extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            password_confirmation: "",
            accept: false,
            openModal: false,
            messageModal: "",
            lang: 'en',
            trad: en,
            load: false,
        };
    }

    componentDidMount() {
        document.title = "Inscription | Wonderplace";

        if (JSON.parse(localStorage.getItem("user"))) {
            this.setState({
                user: JSON.parse(localStorage.getItem("user")),
            }, () => {
                let lang = this.state.user.user.language;
                if (lang === "fr") {
                    this.setState({lang: "fr", trad: fr});
                }
            });
        }
    }

    setItemPromise = (key, value) => {
        return new Promise((resolve) => {
            localStorage.setItem(key, value);
            resolve();
        });
    };
    handleInscription = () => {
        const {email, password, password_confirmation, accept} = this.state;
        let url = import.meta.env.VITE_BACKEND_URL + "/utilisateur/store";
        let emailtest = this.validateEmail(email);
        if (emailtest !== null) {
            if (
                password === password_confirmation &&
                password !== "" &&
                password_confirmation !== ""
            ) {
                if (password.length > 7) {
                    if (accept) {
                        this.setState({load: true}, () => {
                            axios
                                .post(url, {
                                    email,
                                    password,
                                    password_confirmation,
                                    acceptCondition: accept,
                                })
                                .then((res) => {
                                    if (res.data !== false) {
                                        if (res.data.id) {
                                            this.setItemPromise(
                                                "inscription",
                                                JSON.stringify(res.data)
                                            ).then(() => {
                                                localStorage.setItem("storageType", "localStorage");
                                                this.setState({
                                                    messageModal:
                                                        this.state.trad.messageInscription.split('\n').map((line, i) =>
                                                            <span key={i}>{line}<br/></span>),
                                                    openModal: true,
                                                    load: false,
                                                });
                                            });
                                        } else {
                                            this.setState({
                                                messageModal: this.state.trad.messageInscription1,
                                                openModal: true,
                                                    load: false,
                                            });
                                        }
                                    } else {
                                        this.setState({
                                            messageModal: this.state.trad.messageInscription1,
                                            openModal: true,
                                                    load: false,
                                        });
                                    }
                                })
                                .catch((error) => {
                                    console.log(error);
                                });
                        });
                    } else {
                        this.setState({
                            messageModal:
                            this.state.trad.messageInscription2,
                            openModal: true,
                        });
                    }
                } else {
                    this.setState({
                        messageModal: this.state.trad.messageInscription3,
                        openModal: true,
                    });
                }
            } else {
                this.setState({
                    messageModal: this.state.trad.messageForgetPassword5,
                    openModal: true,
                });
            }
        } else {
            this.setState({
                messageModal: this.state.trad.messageInscription4,
                openModal: true,
            });
        }
    };

    handleCloseModal(e) {
        e.preventDefault();
        this.setState({
            messageModal: "",
            openModal: false,
        },() => {
            window.location.href = "/?login=false";
        });
    }

    validateEmail(email) {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,4}))$/
        );
    }

    handleReloadEmail = (e) => {
        e.preventDefault();
        let user = JSON.parse(localStorage.getItem("inscription"));
        console.log(user);
        let url = import.meta.env.VITE_BACKEND_URL + "/utilisateur/sendEmail";
        axios.post(url,
            {
                email: user.email
            }).then((res) => {

        }).catch( (error) => {
            console.log(error);
        });
    }

    render() {
        return (
            <>
                {this.state.openModal && (
                    <div className="modal">
                        <div className="block mini">
                            <p>{this.state.messageModal}</p>
                            <p>{this.state.trad.email_message}</p>
                            <a href="" onClick={this.handleReloadEmail}>{this.state.trad.send_email}</a>
                            <a href="" onClick={(e) => this.handleCloseModal(e)}>
                                OK
                            </a>
                        </div>
                    </div>
                )}
                <div className="inscriptionGlobal">
                    <div className="left">
                        <div className="left_inside">
                            <h2></h2>
                            <p></p>
                        </div>
                    </div>

                    <div className="right">
                        <div className="right_inside">
                            <h1>{this.state.trad.inscriptionTitle}</h1>
                            <div className="form_connexion">
                                <div className="form_control">
                                    <label htmlFor="email">{this.state.trad.passwordForget2}</label>
                                    <input
                                        type="email"
                                        id="email"
                                        name="email"
                                        value={this.state.email}
                                        onChange={(e) => this.setState({email: e.target.value})}
                                    />
                                </div>

                                <div className="form_control">
                                    <label htmlFor="password">{this.state.trad.passwordForget4}</label>
                                    <input
                                        type="password"
                                        id="password"
                                        name="password"
                                        value={this.state.password}
                                        pattern="^(?=.*[a-z])(?=.*[A-Z]).+$"
                                        onChange={(e) =>
                                            this.setState({password: e.target.value})
                                        }
                                    />
                                </div>

                                <div className="form_control">
                                    <label htmlFor="password_confirmation">
                                        {this.state.trad.labelPasswordConfirmation}
                                    </label>
                                    <input
                                        type="password"
                                        id="password_confirmation"
                                        name="password_confirmation"
                                        value={this.state.password_confirmation}
                                        onChange={(e) =>
                                            this.setState({password_confirmation: e.target.value})
                                        }
                                    />
                                </div>

                                <div className="form_control">
                                    <input
                                        type="checkbox"
                                        id="accept"
                                        name="accept"
                                        value={this.state.accept}
                                        onChange={(e) =>
                                            this.setState({accept: e.target.checked})
                                        }
                                    />
                                    <label htmlFor="accept">{this.state.trad.labelValidCondition}</label>
                                </div>

                                <div className="form_control">
                                    <p className="storage_text">{this.state.trad.labelValidCondition2} <Link
                                        to="/">{this.state.trad.labelValidCondition3}</Link>.</p>
                                </div>

                                <div className="form_submit">
                                    {
                                        this.state.load ?
                                            <div className="loading-wave">
                                                <div className="loading-bar"></div>
                                                <div className="loading-bar"></div>
                                                <div className="loading-bar"></div>
                                                <div className="loading-bar"></div>
                                            </div>
                                            :
                                            <img
                                                src={this.state.lang === "fr" ? inscriptionButton : inscriptionButtonen}
                                                alt="se connecter"
                                                className="seconnecter"
                                                onClick={this.handleInscription}
                                            />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
